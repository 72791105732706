import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import axios from 'axios';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { Toolbar } from "primereact/toolbar";
import { Paginator } from 'primereact/paginator';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from "primereact/calendar";
import moment from 'moment';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import { Redirect, useHistory, Link } from "react-router-dom";
import * as Zones from '../../enums/Zones';
import '../../../src/assets/layout/styling.css';
import * as GlobalEnum from "../../enums/GlobalEnum";

const Flight = () => {
    let emptyFlight = {
        id: null,
        flightid: null,
        flightNo: '',
        boardingCity: null,
        from: null,
        to: null,
        departOn: Date,
        arriveAt: Date,
        airlineName: null,
        cityName: '',
        toId: null,
        fromId: null,
        departOnTime: Date,
        arriveAtTime: Date,
        airlineId: null
    };

    const toast = useRef(null);
    const [selectedFlight, setSelectedFlight] = useState([]);
    const dt = useRef(null);
    const [flightDialog, setFlightDialog] = useState(false);
    const [flight, setFlight] = useState(emptyFlight);
    const [submitted, setSubmitted] = useState(false);
    const [deleteFlightDialog, setDeleteFlightDialog] = useState(false);
    const [boardingCity, setBoardingCity] = useState();
    const [allCity, setAllCity] = useState();
    const [allAirline, setAllAirline] = useState();
    const [BoardingCityfilter, setBoardingCityfilter] = useState(null);
    const [departureFilter, setdepartureFilter] = useState(null);
    const [arrivalFilter, setarrivalFilter] = useState(null);
    const [routeFilter, setrouteFilter] = useState(null);    
    const [dobEmployee, setDobEmployee] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [boardingcitydropdowndata, setboardingcitydropdowndata] = useState(null);
    const [citydropdowndata, setcitydropdowndata] = useState(null);
    const [airlinedropdowndata, setairlinedropdowndata] = useState(null);
    const [flightfromdropdowndata, setflightfromdropdowndata] = useState(null);
    const [zonedropdown, setzonedropdown] = useState(null);
    let orderedSelectedColumns = [];
    let unSelectedColumns = [];
    const [selectdata, setselecteddata] = useState({});

    const [fromDropdown, setFromDropdown] = useState(null);
    const [fromToAirport, setFromToAirport] = useState(false);
    const [departArriveDate, setDepartArriveDate] = useState(false);
    const [departArriveTime, setDepartArriveTime] = useState(false);
    const [arriveDepartTime, setArriveDepartTime] = useState(false);
    const [flightNo, setFlightNo] = useState(false);
    const [expandedRows, setExpandedRows] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);

    const UserName = localStorage.getItem('Username');
    const date = new Date();
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [airPortFromName, setAirPortFromName] = useState("");
    const [airPortToName, setAirPortToName] = useState("");
    const [airLineName, setAirLineName] = useState("");
    const [flightNumber, setFlightNumber] = useState("");
    const [cityValue, setcityValue] = useState([]);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        boardingCityfilter: null,
        departureFilter: null,
        arrivalFilter: null,
        zonefilter: null,
        IsInWardfilter: null,
        abc: null,
        routeFilter: null,
        isDirect: null,
    }); // For Sorting And Pagination Purpose
    const [isExportExcel, setIsExportExcel] = useState(false);
    const [enableFilter, setEnableFilter] = useState(false);
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        getFlightList();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    const getFlightList = (isExportExcel = false, event) => {
        setIsLoading(true);
        if (!isExportExcel) setSelectedFlight([]);
        axios.post('/api/flight/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event && event.sortField ? event.sortField : "",
            City: lazyState.current.boardingCityfilter ? lazyState.current.boardingCityfilter.trim() : null,
            Departure: lazyState.current.departureFilter ? lazyState.current.departureFilter.trim() : null,
            Arrival: lazyState.current.arrivalFilter ? lazyState.current.arrivalFilter.trim() : null,
            Zone: lazyState.current.zonefilter ? lazyState.current.zonefilter.name.trim() : null,
            In_Out: lazyState.current.IsInWardfilter ? lazyState.current.IsInWardfilter.code.trim() : null,
            Route: lazyState.current.routeFilter ? lazyState.current.routeFilter.trim() : null,
            IsDirect : lazyState.current.isDirect ? lazyState.current.isDirect.trim() : null,
        }).then((Response) => {
            // if (!isExportExcel) setSelectedFlight([]);
            // let tempData = [];
            // for (let dataObj of Response.data.data) {
            //     // Pass body data in excelsheets.
            //     let allExcelData;
            //     allExcelData = {
            //         Zone: dataObj.zone,
            //         BoardingCity: dataObj.boardingcity,
            //         DepartureAirport: dataObj.departfrom,
            //         DepartDateTime: moment(dataObj.departonTime).format('DD/MM/YYYY HH:mm'),
            //         ArrivalCity:dataObj.arrivecity,
            //         ArrivalAirport: dataObj.arriveat,
            //         ArriveDatetime: moment(dataObj.arriveAtTime).format('DD/MM/YYYY HH:mm'),
            //         TicketPrice: dataObj.actualTicketPrice,
            //         IsDirect:dataObj.isDirect === "True" ? 'Yes' : 'No'
            //     }
            //     tempData.push(allExcelData);
            // }
            // if (isExportExcel) {
            //     exportData(tempData, 'Flight');
            //     setIsLoading(false);
            //     return false;
            // }
            setTotalRecords(Response.data.totalRecords);
            setSelectedFlight(Response.data.data);
            getBoardingCityList();
            getAirportList();
            getAirlineList();
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getFlightExcelData = (isExportExcel = false) => {
        setIsLoading(true);
        axios.get('/api/flight/GetConnFlightDetailsForExcel').then((Response) => {
            let tempData = [];
            for (let dataObj of Response.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                allExcelData = {
                    Zone: dataObj.zone,
                    BoardingCity: dataObj.boardingcity,
                    DepartureAirport: dataObj.departfrom,
                    DepartDateTime: moment(dataObj.departonTime).format('DD/MM/YYYY HH:mm'),
                    ArrivalCity: dataObj.arrivecity,
                    ArrivalAirport: dataObj.arriveat,
                    ArriveDatetime: moment(dataObj.arriveAtTime).format('DD/MM/YYYY HH:mm'),
                    TicketPrice: dataObj.actualTicketPrice,
                    IsDirect: dataObj.isDirect === "True" ? 'Yes' : 'No',
                    InOut: dataObj.inOut  === "True" ? 'Inward' : 'Outward' ,
                    INF1FlightNo: dataObj.inFlightNo1,
                    INF1Airline: dataObj.inAirlineName1,
                    INF1DepartFrom: dataObj.inDepartFrom1,
                    INF1DepartureDateTime: dataObj.inDepartureDateTime1 ? moment(dataObj.inDepartureDateTime1).format('DD/MM/YYYY HH:mm') : '',
                    INF1ArriveAt: dataObj.inArriveAt1,
                    INF1ArrivalDateTime: dataObj.inArrivalDateTime1 ? moment(dataObj.inArrivalDateTime1).format('DD/MM/YYYY HH:mm') : '',
                    INF2FlightNo: dataObj.inFlightNo2,
                    INF2Airline: dataObj.inAirlineName2,
                    INF2DepartFrom: dataObj.inDepartFrom2,
                    INF2DepartureDateTime: dataObj.inDepartureDateTime2 ? moment(dataObj.inDepartureDateTime2).format('DD/MM/YYYY HH:mm') : '',
                    INF2ArriveAt: dataObj.inArriveAt2,
                    INF2ArrivalDateTime: dataObj.inArrivalDateTime2 ? moment(dataObj.inArrivalDateTime2).format('DD/MM/YYYY HH:mm') : '',
                    INF3FlightNo: dataObj.inFlightNo3,
                    INF3Airline: dataObj.inAirlineName3,
                    INF3DepartFrom: dataObj.inDepartFrom3,
                    INF3DepartureDateTime: dataObj.inDepartureDateTime3 ? moment(dataObj.inDepartureDateTime3).format('DD/MM/YYYY HH:mm') : '',
                    INF3ArriveAt: dataObj.inArriveAt3,
                    INF3ArrivalDateTime: dataObj.inArrivalDateTime3 ? moment(dataObj.inArrivalDateTime3).format('DD/MM/YYYY HH:mm') : '',
                    INF4FlightNo: dataObj.inFlightNo4,
                    INF4Airline: dataObj.inAirlineName4,
                    INF4DepartFrom: dataObj.inDepartFrom4,
                    INF4DepartureDateTime: dataObj.inDepartureDateTime4 ? moment(dataObj.inDepartureDateTime4).format('DD/MM/YYYY HH:mm') : '',
                    INF4ArriveAt: dataObj.inArriveAt4,
                    INF4ArrivalDateTime: dataObj.inArrivalDateTime4 ? moment(dataObj.inArrivalDateTime4).format('DD/MM/YYYY HH:mm') : '',
                }
                tempData.push(allExcelData);
            }
            if (isExportExcel) {
                exportData(tempData, 'Flight');
                setIsLoading(false);
                return false;
            }
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getBoardingCityList = () => {
        axios.get(`/api/City/BoardingCityList`)
            .then(res => {
                const data = res.data;
                let city = []
                for (const dataObj of res.data) {
                    city.push({ name: dataObj.name, code: dataObj.id });
                };
                setcityValue(city);
                setBoardingCity(data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const getAirportList = () => {
        axios.post(`/api/airport/GetAll`, {
            paginationRequired: false
        })
            .then(res => {
                const data = res.data.data;
                for (const dataObj of data) {
                    dataObj.name = dataObj.name + ' [' + dataObj.airportCode + ']';
                };
                setAllCity(data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const getAirlineList = () => {
        axios.post(`/api/Airline/GetAll`, {
            paginationRequired: false
        })
            .then(res => {
                const data = res.data.data;
                setAllAirline(data);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const onRowExpand = (event) => {
        axios.get(`/api/flight/GetConnFlightDetails/${event.data.flightid}`).then(Response => {
            const index = selectedFlight.findIndex(emp => emp.flightid === event.data.flightid); 
            var flights = [...selectedFlight]; 
            event.data.connectingFlight = Response.data;
            flights[index] = event.data;
            setSelectedFlight(flights);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const rowExpansionTemplate = (e) => {
        return (
            <div className="p-3">
                <DataTable value={e.connectingFlight} responsiveLayout="scroll" >
                    <Column field="flightNo" header="Flight No"></Column>
                    <Column field="airline" header="Airline"></Column>
                    <Column field="departfromname" header="Boarding"></Column>
                    <Column body={DepartOnFormaterowexpand} field="departondatetime" header="Boarding Time"></Column>
                    <Column field="arriveatname" header="Arriving"></Column>
                    <Column body={ArriveAtFormaterowexpand} field="arriveatdatetime" header="Arriving Time"></Column>
                    {/* <Column field="ticketPrice" header="Ticket Price"></Column> */}
                </DataTable>
            </div>
        );
    };

    const FlightDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={(e) => saveFlight(e)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { hideDialog(e); }} outlined />
        </>
    );

    const deleteFlightDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={(e) => deleteFlight()} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={(e) => hideDeleteFlightDialog(e)} outlined />
        </>
    );

    const hideDeleteFlightDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto');
        setDeleteFlightDialog(false);
    };

    const fromDropdownData = (flight) => {
        if (allCity) {
            let fromCity = allCity.filter((cvalue) => {
                if (flight.id) {
                    if (flight.boardingCity) {
                        if (typeof flight.boardingCity === 'object') {
                            return cvalue.cityId == flight.boardingCity.id;
                        } else {
                            return cvalue.cityId == flight.boardingCity;
                        };
                    };
                };
                if (flight.id == null) {
                    if (flight.boardingCity) {
                        return cvalue.cityId == flight.boardingCity.id;
                    };
                };
            });
            setFromDropdown(fromCity);
        };
    };

    const BoardingCityTemplate = () => {
        return (
            <InputText id="boardingcity" required value={BoardingCityfilter} placeholder="Enter Boarding city"
                onChange={(e) => setBoardingCityfilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const DepartureTemplate = () => {
        return (
            <InputText id="departure" required value={departureFilter} placeholder="Enter Departure From"
                onChange={(e) => setdepartureFilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };
    const ArrivalTemplate = () => {
        return (
            <InputText id="arrival" required value={arrivalFilter} placeholder="Enter Arrive At"
                onChange={(e) => setarrivalFilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };
    const RouteTemplate = () => {
        return (
            <InputText id="route" required value={routeFilter} placeholder="Enter Route"
                onChange={(e) => setrouteFilter(e.target.value)} onKeyDown={(e) => FilterOnEnter(e)} style={{ minWidth: '12rem' }} />
        );
    };

    const ZoneTemplate = () => {
        return (
            <Dropdown value={lazyState.current.zonefilter} options={Zones.Zone} optionLabel="name" showClear onChange={(e) => { lazyState.current.zonefilter = (e.target.value ? e.target.value : null); getFlightList(isExportExcel, { Zone: e.value }); e.preventDefault() }} placeholder="Select Zone" className="p-column-filter" />
        )
    };

    const IsInWardFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.IsInWardfilter}
                options={GlobalEnum.InWard}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.IsInWardfilter = e.target.value ? e.target.value : null;
                    getFlightList(isExportExcel, { IsInWard: e.value });
                    e.preventDefault();
                }}
                placeholder="Select One"
                className="p-column-filter"
            />
        );
    };

    const IsDirectFilterTemplate = () => {
        return (
            <Dropdown
                value={lazyState.current.isDirect}
                options={GlobalEnum.GlobalEnum}
                optionLabel="name"
                showClear
                onChange={(e) => {
                    lazyState.current.isDirect = e.target.value ? e.target.value : null;
                    getFlightList(isExportExcel, { IsDirect: e.value });
                    e.preventDefault();
                }}
                placeholder="Select Is Direct"
                className="p-column-filter"
            />
        );
    };
    

    const FilterOnEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "boardingcity":
                        lazyState.current.boardingCityfilter = e.target.value ? e.target.value : null
                        break;
                    case "departure":
                        lazyState.current.departureFilter = e.target.value ? e.target.value : null
                        break;
                    case "arrival":
                        lazyState.current.arrivalFilter = e.target.value ? e.target.value : null
                        break;
                    case "route":
                        lazyState.current.routeFilter = e.target.value ? e.target.value : null
                        break;
                    // case "zone":
                    //     lazyState.current.zonefilter = e.target.value ? e.target.value : null
                    //     break;
                };
                getFlightList(isExportExcel, lazyState.current);
                setIsLoading(false);
            } else{
                setIsLoading(true);
                let data = e.target.id;
                switch (data) {
                    case "boardingcity":
                        lazyState.current.boardingCityfilter = null
                        break;
                    case "departure":
                        lazyState.current.departureFilter = null
                        break;
                    case "arrival":
                        lazyState.current.arrivalFilter = null
                        break;
                    case "route":
                        lazyState.current.routeFilter = null
                        break;
                };
                getFlightList(isExportExcel, lazyState.current);
                setIsLoading(false);
            };
        };
    };

    const removeFilter = () => {
        lazyState.current.boardingCityfilter = null;
        setBoardingCityfilter(null);
        lazyState.current.departureFilter = null;
        setdepartureFilter(null);
        lazyState.current.arrivalFilter = null;
        setarrivalFilter(null);
        lazyState.current.zonefilter = null;
        getFlightList(isExportExcel, lazyState.current);
    };

    const editFlight = (flight) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setFlightDialog(true);
        handleEdit(flight.id);
        setFlightNo(false);
        setFromToAirport(false);
        setDepartArriveTime(false);
        setArriveDepartTime(false);
        setDepartArriveDate(false);
        setFlight({ ...flight });
        fromDropdownData(flight);
    };

    const confirmDeleteFlight = (flight) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setFlight(flight);
        setDeleteFlightDialog(true);
    };

    const deleteFlight = () => {
        setIsLoading(true);
        axios.delete(`api/flight/delete/${flight.flightid}`)
            .then(res => {
                const data = res.data;
                setFlight(data);
                getFlightList(isExportExcel, lazyState.current);
                setIsLoading(false);
                toast.current.show({
                    severity: "success",
                    summary: "Successful",
                    detail: "Flight Deleted",
                    life: 3000
                });
                setDeleteFlightDialog(false);
                // document.documentElement.style.setProperty('overflow', 'auto');
            });
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <>
                    <AccessControl
                        allowedPermissions={["edit:flight"]}>
                        <Link to={'/home/editflight/' + rowData.flightid}>
                            <Button icon="fa fa-pencil" className="p-button-rounded mr-2 flight-actionbutton" tooltip="Edit"
                                tooltipOptions={{ position: 'bottom', className: "showtooltip" }} outlined />
                        </Link>
                    </AccessControl>
                    <AccessControl
                        allowedPermissions={["delete:flight"]}>
                        <Button icon="fa fa-trash" className="p-button-rounded mr-2 flight-actionbutton" onClick={() => confirmDeleteFlight(rowData)}
                            tooltip="Delete" tooltipOptions={{ position: 'bottom', className: "showtooltip" }} outlined />
                    </AccessControl>
                </>
            </div>
        );
    };

    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setSubmitted(false);
        setFlightDialog(false);
        setFlight(emptyFlight);
        setboardingcitydropdowndata(null);
        setflightfromdropdowndata(null);
        setcitydropdowndata(null);
        setairlinedropdowndata(null);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getFlightList(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
        setIsLoading(true);
        lazyState.current.sortField = lazyState.current.sortField;
        lazyState.current.sortOrder = lazyState.current.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getFlightList(isExportExcel, lazyState.current);
        setIsLoading(false);
        // };
    };

    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        }
    };

    const saveFlight = (e) => {
        setSubmitted(true);
        let formateArriveAtTime;
        let formateDepartonTime;

        if (flight.id) {
            if (flight.departOn != null) {
                let DepartOnData = new Date(flight.departOn);
                let datetime;
                let time;
                time = DepartOnData.getFullYear() + "-" + (DepartOnData.getMonth() + 1) + "-" + DepartOnData.getDate();
                if (flight.departOnTime != undefined) {
                    if (flight.departOnTime?.name != 'Date') {
                        datetime = flight.departOnTime?.getHours() + ":" + String(flight.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.departOnTime?.getSeconds()).padStart(2, "0");
                    };
                };
                if (flight.departOnTime == undefined) {
                    datetime = DepartOnData.getHours() + ":" + String(DepartOnData.getMinutes()).padStart(2, "0") + ":" + String(DepartOnData.getSeconds()).padStart(2, "0");
                };
                formateDepartonTime = time += " " + (datetime == undefined ? '' : datetime);
            };

            if (flight.departOn == null && flight.departOnTime != undefined) {
                let defaultDepartOnDate = new Date();
                let datetime;
                defaultDepartOnDate = defaultDepartOnDate.getFullYear() + "-" + (defaultDepartOnDate.getMonth() + 1) + "-" + defaultDepartOnDate.getDate();
                if (flight.departOnTime != undefined) {
                    if (flight.departOnTime?.name != 'Date') {
                        datetime = flight.departOnTime?.getHours() + ":" + String(flight.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.departOnTime?.getSeconds()).padStart(2, "0");
                    };
                };
                formateDepartonTime = defaultDepartOnDate += " " + (datetime == undefined ? '' : datetime);
            };

            if (flight.arriveAt != null) {
                let arriveAtData = new Date(flight.arriveAt);
                let arriveAtTm;
                let arriveAtDate;
                arriveAtDate = arriveAtData.getFullYear() + "-" + (arriveAtData.getMonth() + 1) + "-" + arriveAtData.getDate();
                if (flight.arriveAtTime != undefined) {
                    arriveAtTm = flight.arriveAtTime?.getHours() + ":" + String(flight.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.arriveAtTime?.getSeconds()).padStart(2, "0");
                };
                if (flight.arriveAtTime == undefined) {
                    arriveAtTm = arriveAtData.getHours() + ":" + String(arriveAtData.getMinutes()).padStart(2, "0") + ":" + String(arriveAtData.getSeconds()).padStart(2, "0");
                };
                formateArriveAtTime = arriveAtDate += " " + (arriveAtTm == undefined ? '' : arriveAtTm);
            };

            if (flight.arriveAt == null && flight.arriveAtTime != undefined) {
                let defaultArriveAtDate = new Date();
                let datetime;
                defaultArriveAtDate = defaultArriveAtDate.getFullYear() + "-" + (defaultArriveAtDate.getMonth() + 1) + "-" + defaultArriveAtDate.getDate();
                if (flight.arriveAtTime != undefined) {
                    if (flight.arriveAtTime.name != 'Date') {
                        datetime = flight.arriveAtTime?.getHours() + ":" + String(flight.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.arriveAtTime?.getSeconds()).padStart(2, "0");
                    };
                };
                formateArriveAtTime = defaultArriveAtDate += " " + (datetime == undefined ? '' : datetime);
            };

            if (!arriveDepartTime && !departArriveTime && !departArriveDate && flight.departOn != Date && flight.arriveAt != Date && formateDepartonTime && formateArriveAtTime && flight.fromId && flight.toId && flight.flightNo) {
                if (flight.flightNo.length > 0 && flight.flightNo.length <= 10 && flight.fromId != flight.toId) {
                    if (flight.flightNo.trim()) {
                        axios.put(`api/flight/update`, {
                            Id: flight.id,
                            FlightNo: flight.flightNo.trim(),
                            BoardingCity: flight.boardingCity ? flight.boardingCity.id == '00000000-0000-0000-0000-000000000000' || flight.boardingCity == '00000000-0000-0000-0000-000000000000' ? null : flight.boardingCity.id || flight.boardingCity : null,
                            From: flight.fromId ? flight.fromId == '00000000-0000-0000-0000-000000000000' ? null : flight.fromId : null,
                            To: flight.toId ? flight.toId == '00000000-0000-0000-0000-000000000000' ? null : flight.toId : null,
                            DepartOn: formateDepartonTime ? new Date(formateDepartonTime) : null,
                            ArriveAt: formateArriveAtTime ? new Date(formateArriveAtTime) : null,
                            AirlineName: flight.airlineId ? flight.airlineId == '00000000-0000-0000-0000-000000000000' ? null : flight.airlineId : null,
                            CreatedBy: CreatedBy,
                            Created: Created,
                            ModifiedBy: UserName,
                            // Modified: date
                        }).then(res => {
                            const data = res.data;
                            setFlight(data);
                            setFlightDialog(false);
                            setboardingcitydropdowndata(null);
                            setflightfromdropdowndata(null);
                            setcitydropdowndata(null);
                            setairlinedropdowndata(null);
                            getFlightList(isExportExcel, lazyState.current);
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Flight Updated', life: 3000 });
                            // document.documentElement.style.setProperty('overflow', 'auto');
                        }).catch((error) => {
                            if (error.response.data.includes("System.Exception: Flight With This Name Already Exist.")) {
                                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Flight Already Exist', life: 3000 });
                            }
                            else {
                                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                            };
                        });
                    };
                };
            };
        };

        if (flight.id == null) {
            if (flight.departOn?.name == undefined) {
                let departDate = new Date(flight.departOn);
                let datetime;
                departDate = flight.departOn?.getFullYear() + "-" + (flight.departOn?.getMonth() + 1) + "-" + flight.departOn?.getDate();
                if (flight.departOnTime != undefined || flight.departOnTime != null) {
                    if (flight.departOnTime?.name != 'Date') {
                        datetime = flight.departOnTime?.getHours() + ":" + String(flight.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.departOnTime?.getSeconds()).padStart(2, "0");
                    }
                }
                formateDepartonTime = departDate += " " + (datetime == undefined ? '' : datetime);
            }

            if (flight.departOn?.name == 'Date' && flight.departOnTime?.name != 'Date') {
                let defaultDepartOnDate = new Date();
                let datetime;
                defaultDepartOnDate = defaultDepartOnDate.getFullYear() + "-" + (defaultDepartOnDate.getMonth() + 1) + "-" + defaultDepartOnDate.getDate();
                if (flight.departOnTime != undefined || flight.departOnTime != null) {
                    if (flight.departOnTime?.name != 'Date') {
                        datetime = flight.departOnTime?.getHours() + ":" + String(flight.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.departOnTime?.getSeconds()).padStart(2, "0");
                    }
                }
                formateDepartonTime = defaultDepartOnDate += " " + (datetime == undefined ? '' : datetime);
            }

            if (flight.arriveAt?.name == undefined) {
                let arriveAtDate = new Date(flight.arriveAt);
                let arriveAtTm;
                arriveAtDate = flight.arriveAt?.getFullYear() + "-" + (flight.arriveAt?.getMonth() + 1) + "-" + flight.arriveAt?.getDate();
                if (flight.arriveAtTime != undefined || flight.arriveAtTime != null) {
                    if (flight.arriveAtTime?.name != 'Date') {
                        arriveAtTm = flight.arriveAtTime?.getHours() + ":" + String(flight.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.arriveAtTime?.getSeconds()).padStart(2, "0");
                    }
                }
                formateArriveAtTime = arriveAtDate += " " + (arriveAtTm == undefined ? '' : arriveAtTm);
            }

            if (flight.arriveAt?.name == 'Date' && flight.arriveAtTime?.name != 'Date') {
                let defaultArriveAtDate = new Date();
                let datetime;
                defaultArriveAtDate = defaultArriveAtDate.getFullYear() + "-" + (defaultArriveAtDate.getMonth() + 1) + "-" + defaultArriveAtDate.getDate();
                if (flight.arriveAtTime != undefined || flight.arriveAtTime != null) {
                    if (flight.arriveAtTime?.name != 'Date') {
                        datetime = flight.arriveAtTime?.getHours() + ":" + String(flight.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(flight.arriveAtTime?.getSeconds()).padStart(2, "0");
                    }
                }
                formateArriveAtTime = defaultArriveAtDate += " " + (datetime == undefined ? '' : datetime);
            }
            if (!arriveDepartTime && !departArriveTime && !departArriveDate && flight.flightNo && flight.departOn != Date && flight.arriveAt != Date && formateDepartonTime && formateDepartonTime.indexOf(":") != -1 && formateArriveAtTime && formateArriveAtTime.indexOf(":") != -1 && flight.boardingCity && flight.fromId && flight.toId && flight.airlineId) {
                if (flight.flightNo.length > 0 && flight.flightNo.length <= 10 && flight.fromId != flight.toId) {
                    if (flight.flightNo.trim()) {
                        axios.post(`api/flight/insert`, {
                            FlightNo: flight.flightNo.trim(),
                            BoardingCity: flight.boardingCity ? flight.boardingCity.id : null,
                            From: flight.fromId ? flight.fromId : null,
                            To: flight.toId ? flight.toId : null,
                            DepartOn: formateDepartonTime ? new Date(formateDepartonTime) : null,
                            ArriveAt: formateArriveAtTime ? new Date(formateArriveAtTime) : null,
                            AirlineName: flight.airlineId ? flight.airlineId : null,
                            CreatedBy: UserName,
                            Created: date,
                        })
                            .then(res => {
                                const data = res.data;
                                setFlight(data);
                                setFlightDialog(false);
                                setboardingcitydropdowndata(null);
                                setflightfromdropdowndata(null);
                                setcitydropdowndata(null);
                                setairlinedropdowndata(null);
                                setSubmitted(false);
                                getFlightList(isExportExcel, lazyState.current);
                                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New Flight Added', life: 3000 });
                                // document.documentElement.style.setProperty('overflow', 'auto');
                            }).catch((error) => {
                                if (error.response.data.includes("System.Exception: Flight With This Name Already Exist.")) {
                                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Flight Already Exist', life: 3000 });
                                }
                                else {
                                    toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                                };
                            });
                    };
                };
            }
            else {
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Please Select Appropriate Time For Flight!', life: 3000 });
            };
        };
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '' || e.value;
        let _flight = { ...flight };
        _flight[`${name}`] = val;
        setFlight(_flight);

        if (name == 'boardingCity') {
            fromDropdownData(_flight);
        }

        if (name == 'flightNo') {
            if (_flight.flightNo != undefined) {
                setFlightNo(false);
                if (_flight.flightNo.length < 0 || _flight.flightNo.length > 10) {
                    setFlightNo(true);
                }
            }
        }

        if (_flight.id) {
            let modifyDepartOnTime;
            let modifyArriveAtTime;
            if (flight.departOn != null && _flight.departOnTime == undefined) {
                let departOnDateTime = new Date(flight.departOn)
                let defaultDate = new Date();
                modifyDepartOnTime = defaultDate.getFullYear() + "-" + defaultDate.getMonth() + "-" + defaultDate.getDate() + " " + departOnDateTime.getHours() + ":" + String(departOnDateTime.getMinutes()).padStart(2, "0") + ":" + String(departOnDateTime.getSeconds()).padStart(2, "0");
                _flight['departOnTime'] = new Date(modifyDepartOnTime);
                setFlight(_flight);
            } else {
                modifyDepartOnTime = _flight.departOnTime ? new Date(_flight.departOnTime) : null;;
            }

            if (flight.arriveAt != null && _flight.arriveAtTime == undefined) {
                let arriveAtDateTime = new Date(flight.arriveAt)
                let defaultDate = new Date();
                modifyArriveAtTime = defaultDate.getFullYear() + "-" + defaultDate.getMonth() + "-" + defaultDate.getDate() + " " + arriveAtDateTime.getHours() + ":" + String(arriveAtDateTime.getMinutes()).padStart(2, "0") + ":" + String(arriveAtDateTime.getSeconds()).padStart(2, "0");
                _flight['arriveAtTime'] = new Date(modifyArriveAtTime);
                setFlight(_flight);
            } else {
                modifyArriveAtTime = _flight.arriveAtTime ? new Date(_flight.arriveAtTime) : null;;
            }

            let modifyDepartOnDate = _flight.departOn ? new Date(_flight.departOn) : null;
            let modifyArriveAtDate = _flight.arriveAt ? new Date(_flight.arriveAt) : null;

            if (modifyArriveAtDate && modifyArriveAtDate && modifyDepartOnTime && modifyArriveAtTime) {
                setDepartArriveTime(false);
                if (modifyArriveAtDate.getDate() + "-" + modifyArriveAtDate.getMonth() + "-" + modifyArriveAtDate.getFullYear() == modifyDepartOnDate.getDate() + "-" + modifyDepartOnDate.getMonth() + "-" + modifyDepartOnDate.getFullYear()) {
                    if (
                        _flight.departOnTime?.getHours() + ":" + String(_flight.departOnTime?.getMinutes()).padStart(2, "0")
                        ==
                        _flight.arriveAtTime?.getHours() + ":" + String(_flight.arriveAtTime?.getMinutes()).padStart(2, "0")) {
                        setDepartArriveTime(true);
                    }
                }
            }
            if (modifyArriveAtDate && modifyDepartOnDate) {
                setDepartArriveDate(false);
                let arriveAtDate = new Date(modifyArriveAtDate);
                let departOnDate = new Date(modifyDepartOnDate);
                let arriveAtDateLength = arriveAtDate.getDate() + "-" + arriveAtDate.getMonth() + "-" + arriveAtDate.getFullYear();
                let departOnDateLength = departOnDate.getDate() + "-" + departOnDate.getMonth() + "-" + departOnDate.getFullYear();
                let arriveAtDateMonth;
                let departOnDateMonth;
                if (arriveAtDateLength.length == 8) {
                    arriveAtDateMonth = "0" + arriveAtDate.getDate();
                } else {
                    arriveAtDateMonth = arriveAtDate.getDate();
                }
                if (departOnDateLength.length == 8) {
                    departOnDateMonth = "0" + departOnDate.getDate()
                } else {
                    departOnDateMonth = departOnDate.getDate();
                }
                if (arriveAtDateMonth + "-" + arriveAtDate.getFullYear() < departOnDateMonth + "-" + departOnDate.getFullYear()) {
                    setDepartArriveDate(true);
                }

                if (arriveAtDateMonth + "-" + arriveAtDate.getFullYear() == departOnDateMonth + "-" + departOnDate.getFullYear()) {
                    setArriveDepartTime(false)
                    let departOnTimeData;
                    let arriveAtTimeData;

                    let departDateData;
                    let ArriveDateData;

                    let departTimeData;
                    let ArriveTimeData;

                    if (_flight.departOnTime?.getHours() < 10) {
                        departDateData = "0" + _flight.departOnTime?.getHours();
                    } else {
                        departDateData = _flight.departOnTime?.getHours()
                    }

                    if (_flight.departOnTime?.getMinutes() < 10) {
                        departTimeData = "0" + _flight.departOnTime?.getMinutes();
                    } else {
                        departTimeData = _flight.departOnTime?.getMinutes()
                    }

                    if (_flight.arriveAtTime?.getHours() < 10) {
                        ArriveDateData = "0" + _flight.arriveAtTime?.getHours();
                    } else {
                        ArriveDateData = _flight.arriveAtTime?.getHours()
                    }

                    if (_flight.arriveAtTime?.getMinutes() < 10) {
                        ArriveTimeData = "0" + _flight.arriveAtTime?.getMinutes();
                    } else {
                        ArriveTimeData = _flight.arriveAtTime?.getMinutes()
                    }

                    departOnTimeData = departDateData + ":" + departTimeData;
                    arriveAtTimeData = ArriveDateData + ":" + ArriveTimeData;

                    if (arriveAtTimeData < departOnTimeData) {
                        setArriveDepartTime(true)
                    }
                }
            }
        }

        if (_flight.id == null) {
            if (_flight.arriveAtTime?.name == undefined && _flight.departOnTime?.name == undefined && _flight.arriveAt?.name == undefined && _flight.departOn?.name == undefined) {
                setDepartArriveTime(false);
                if (_flight.arriveAt?.getDate() + "-" + _flight.arriveAt?.getMonth() + "-" + _flight.arriveAt?.getFullYear() == _flight.departOn?.getDate() + "-" + _flight.departOn?.getMonth() + "-" + _flight.departOn?.getFullYear()) {
                    if (
                        _flight.departOnTime?.getHours() + ":" + String(_flight.departOnTime?.getMinutes()).padStart(2, "0") + ":" + String(_flight.departOnTime?.getSeconds()).padStart(2, "0")
                        ==
                        _flight.arriveAtTime?.getHours() + ":" + String(_flight.arriveAtTime?.getMinutes()).padStart(2, "0") + ":" + String(_flight.arriveAtTime?.getSeconds()).padStart(2, "0")) {
                        setDepartArriveTime(true);
                    }
                }
            }
            if (_flight.arriveAt?.name == undefined && _flight.departOn?.name == undefined) {
                setDepartArriveDate(false);
                let arriveAtDate = new Date(_flight.arriveAt);
                let departOnDate = new Date(_flight.departOn);
                let arriveAtDateLength = arriveAtDate.getDate() + "-" + arriveAtDate.getMonth() + "-" + arriveAtDate.getFullYear();
                let departOnDateLength = departOnDate.getDate() + "-" + departOnDate.getMonth() + "-" + departOnDate.getFullYear();
                let arriveAtDateMonth;
                let departOnDateMonth;
                if (arriveAtDateLength.length == 8) {
                    arriveAtDateMonth = "0" + arriveAtDate.getDate() + "-" + "0" + arriveAtDate.getMonth();
                } else {
                    arriveAtDateMonth = arriveAtDate.getDate() + "-" + arriveAtDate.getMonth();
                }
                if (departOnDateLength.length == 8) {
                    departOnDateMonth = "0" + departOnDate.getDate() + "-" + "0" + departOnDate.getMonth();
                } else {
                    departOnDateMonth = departOnDate.getDate() + "-" + departOnDate.getMonth();
                }
                if (arriveAtDateMonth + "-" + arriveAtDate.getFullYear() < departOnDateMonth + "-" + departOnDate.getFullYear()) {
                    setDepartArriveDate(true);
                }

                if (arriveAtDateMonth + "-" + arriveAtDate.getFullYear() == departOnDateMonth + "-" + departOnDate.getFullYear()) {
                    setArriveDepartTime(false)
                    if (_flight.arriveAtTime?.name != 'Date' && _flight.departOnTime?.name != 'Date') {

                        let departOnTimeData;
                        let arriveAtTimeData;

                        let departDateData;
                        let ArriveDateData;

                        let departTimeData;
                        let ArriveTimeData;

                        if (_flight.departOnTime?.getHours() < 10) {
                            departDateData = "0" + _flight.departOnTime?.getHours();
                        } else {
                            departDateData = _flight.departOnTime?.getHours()
                        }

                        if (_flight.departOnTime?.getMinutes() < 10) {
                            departTimeData = "0" + _flight.departOnTime?.getMinutes();
                        } else {
                            departTimeData = _flight.departOnTime?.getMinutes()
                        }

                        if (_flight.arriveAtTime?.getHours() < 10) {
                            ArriveDateData = "0" + _flight.arriveAtTime?.getHours();
                        } else {
                            ArriveDateData = _flight.arriveAtTime?.getHours()
                        }

                        if (_flight.arriveAtTime?.getMinutes() < 10) {
                            ArriveTimeData = "0" + _flight.arriveAtTime?.getMinutes();
                        } else {
                            ArriveTimeData = _flight.arriveAtTime?.getMinutes()
                        }

                        departOnTimeData = departDateData + ":" + departTimeData;
                        arriveAtTimeData = ArriveDateData + ":" + ArriveTimeData;

                        if (arriveAtTimeData < departOnTimeData) {
                            setArriveDepartTime(true)
                        };
                    };
                };
            };
        };
    };

    const handleEdit = (id) => {
        setIsLoading(true);
        axios.get('/api/flight/GetAsync/' + id)
            .then((Response) => {
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
            });
    };

    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                {/* <Link to={'/home/addflight'}> <Button label="Add Flight" icon="fa fa-plus" className="p-button-success mr-2" /> </Link> */}
                <Link to={{ pathname: '/home/addflight', state: {  name: 'AddConnFlight'  } }}><Button label="Add Two-Way Flight" icon="fa fa-plus" className="p-button-success mr-2" /> </Link>
                <Link to={{ pathname: '/home/addflight', state: {  name: 'AddOnwardsFlight'  } }}><Button label="Add Onwards Flight" icon="fa fa-plus" className="p-button-success mr-2" /> </Link>
                <Link to={{ pathname: '/home/addflight', state: {  name: 'AddReturnFlight'  } }}><Button label="Add Return Flight" icon="fa fa-plus" className="p-button-success mr-2" /> </Link>
            </div>
            // <AccessControl
            //     allowedPermissions={["add:flight"]}>
            //     <>
            //         <Button
            //             label="Add Flight"
            //             icon="fa fa-plus"
            //             onClick={openNew}
            //             outlined
            //         />
            //     </>
            // </AccessControl>
        );
    };

    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setFlight(emptyFlight);
        setSubmitted(false);
        setFlightNo(false)
        setFromToAirport(false)
        setDepartArriveTime(false)
        setArriveDepartTime(false)
        setDepartArriveDate(false)
        setFlightDialog(true);
    };

    const DepartOnFormate = (selectedFlight) => {
        if (selectedFlight.departonTime == null) {
            return null;
        } else {
            const demployee = moment(selectedFlight.departonTime).format('DD/MM/YYYY HH:mm');
            setDobEmployee(demployee);
            return demployee;
        }
    }

    const ArriveAtFormate = (selectedFlight) => {
        if (selectedFlight.arriveAtTime == null) {
            return null;
        } else {
            const demployee = moment(selectedFlight.arriveAtTime).format('DD/MM/YYYY HH:mm');
            setDobEmployee(demployee);
            return demployee;
        }
    }

    const DepartOnFormaterowexpand = (event) => {
        if (event.departondatetime == null) {
            return null;
        } else {
            const departdate = moment(event.departondatetime).format('DD/MM/YYYY HH:mm');
            return departdate;
        }
    }

    const ArriveAtFormaterowexpand = (event) => {
        if (event.arriveatdatetime == null) {
            return null;
        } else {
            const arrivedate = moment(event.arriveatdatetime).format('DD/MM/YYYY HH:mm');
            return arrivedate;
        }
    }

    const onFromDropdownChange = (e) => {
        let _flight = { ...flight };
        _flight[`from`] = (e.name) || '';
        _flight[`fromId`] = (e.id) || '';
        setFlight(_flight);
        setFromToAirport(false);
        if (_flight.fromId == _flight.toId) {
            setFromToAirport(true);
        }
    }

    const onToDropdownChange = (e) => {
        let _flight = { ...flight };
        _flight['to'] = (e.name) || '';
        _flight['toId'] = (e.id) || '';
        setFlight(_flight);
        setFromToAirport(false);
        if (_flight.fromId == _flight.toId) {
            setFromToAirport(true);
        }
    }

    const onAirlineDropdownChange = (e) => {
        let _flight = { ...flight };
        _flight['airlineName'] = (e.name) || '';
        _flight['airlineId'] = (e.id) || '';
        setFlight(_flight);
    }

    const NoFilter = () => {
        return (
            <></>
        )
    }
    const exportExcel = () => {
        // getFlightList(true);
        getFlightExcelData(true);
    }


    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.boardingCityfilter == null && lazyState.current.departureFilter == null
                    && lazyState.current.arrivalFilter == null && lazyState.current.zonefilter == null}
                    icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>

                {/* <div className="my-2">{header}</div> */}
                <AccessControl
                    allowedPermissions={["export:flight"]}>
                    <Button disabled={!(selectedFlight != null && selectedFlight.length > 0)} onClick={() => { exportExcel(); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    }

    //#region Method for Column toggle
    const hidecolumns = [
        { field: "departOn", header: "DepartOn" },
        { field: "arriveAt", header: "ArriveAt" },
        { field: "airlineName", header: "Airline Name" },
    ];

    const onColumnToggle = (event) => {
        let selectedColumns = event.value;
        orderedSelectedColumns = hidecolumns.filter(col => selectedColumns.some(sCol => sCol.field === col.field));
        unSelectedColumns = hidecolumns.filter(o => !selectedColumns.some(i => i.field === o.field && i.header === o.header));
        unselectedColumnData();
        setSelectedColumns(orderedSelectedColumns);
    };

    const unselectedColumnData = () => {
        let isFiltered = false;
        if (unSelectedColumns) {
            unSelectedColumns.map(col => {
                switch (col.header) {
                    case "Airline Name":
                        if (lazyState.current.airlineName != null) {
                            lazyState.current.airlineName = null;
                            return setAirLineName(null);
                        };
                        break;
                };
            });
        };
    };

    const hideColumnsFilterTemplate = (column) => {
        switch (column) {
            // case "Airline Name":
            // return AirlineNameFilter;
            case "DepartOn":
                return NoFilter;
            case "ArriveAt":
                return NoFilter;
        }
    }

    const sortColumn = {
        "DepartOn": 'DepartOn',
        "ArriveAt": 'ArriveAt',
        "Airline Name": 'AirlineName',
    }

    const hidecolumnComponents = selectedColumns.map(col => {
        return <Column key={col.field} field={col.field} header={col.header} sortable headerStyle={{ width: '15%', minWidth: '15rem' }} sortField={sortColumn[col.header]}
            filter showFilterMenu={false} filterElement={hideColumnsFilterTemplate(col.header)} body={col.header == 'DepartOn' ? DepartOnFormate : col.header == 'ArriveAt' ? ArriveAtFormate : null} />;
    });

    const IsDirectIcon = (rowData) => {
        if (rowData.isDirect === "True") {
            return <i className="pi pi-check" style={{ fontSize: '20px', color: 'green' }}></i>;
        } else {
            return <i className="pi pi-times" style={{ fontSize: '20px', color: 'red' }}></i>;
        }
    };

    //#endregion

    return (
        <AccessControl
            allowedPermissions={["list:flight"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid  fa-plane fa-rotate-110 fa-cog mr-2"></i>Flights</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable
                                        expandedRows={expandedRows} onRowToggle={(e) => setExpandedRows(e.data)}
                                        onRowExpand={(e) => onRowExpand(e)} rowExpansionTemplate={(e) => rowExpansionTemplate(e)}
                                        size="small" lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort ref={dt} value={selectedFlight} selection={selectedFlight}
                                        onSelectionChange={(e) => setSelectedFlight(e.value)} scrollable scrollHeight="calc(100vh - 250px)"
                                        responsiveLayout="scroll" filterDisplay={"row"}>
                                        {/* <Column field="flightNo" header="FlightNo" sortField="FlightNo" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={FlightNumberTemplate}></Column>
                                        <Column field="cityName" header="BoardingCity" sortField="BoardingCity" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={CityTemplate}></Column>
                                        <Column field="from" header="From" sortField="From" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={AirportNameFromFilterTemplate}></Column>
                                        <Column field="to" header="To" sortField="To" sortable headerStyle={{ width: '15%', minWidth: '15rem' }} style={{ overflowWrap: 'anywhere' }} filter showFilterMenu={false} filterElement={AirportNameToFilterTemplate}></Column> */}
                                        <Column header="Action" body={actionBodyTemplate} headerStyle={{ width: '10%', minWidth: '7rem' }} exportable={false}></Column>
                                        <Column expander={true} />
                                        <Column field="zone" header="Zone" sortField="Zone" sortable filterElement={ZoneTemplate} headerStyle={{ width: '10%', minWidth: '10rem' }} filter showFilterMenu={false}></Column>
                                        <Column field="boardingcity" header="Boarding City" sortField="boardingcity" headerStyle={{ width: '10%', minWidth: '10rem' }} sortable filterElement={BoardingCityTemplate} filter showFilterMenu={false}></Column>
                                        <Column field="route" header="Route" sortField="Route" sortable headerStyle={{ width: '10%', minWidth: '10rem' }} filterElement={RouteTemplate} filter showFilterMenu={false}></Column>
                                        <Column field="departfrom" header="Departure" sortField="BoardingCity" headerStyle={{ width: '10%', minWidth: '10rem' }} sortable filterElement={DepartureTemplate} filter showFilterMenu={false}></Column>
                                        <Column body={DepartOnFormate} field="departonTime" header="Depart Time" sortField="DepartOnTime" headerStyle={{ width: '10%', minWidth: '10rem' }} sortable ></Column>
                                        <Column field="arriveat" header="Arrival" sortField="To" headerStyle={{ width: '10%', minWidth: '10rem' }} sortable filterElement={ArrivalTemplate} filter showFilterMenu={false}></Column>
                                        <Column body={ArriveAtFormate} field="arriveAtTime" header="Arrive time" sortField="ArriveAtTime" headerStyle={{ width: '10%', minWidth: '10rem' }} sortable ></Column>
                                        <Column body={IsDirectIcon} field="isDirect" header="IsDirect" sortField="IsDirect" sortable ></Column>
                                        {/* <Column field="isDirect" header="IsDirect2" sortField="IsDirect" sortable ></Column>
                                        
                                        <Column field="isDirect" sortField="IsDirect" sortable header="IsDirect2" headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsDirectFilterTemplate}></Column> */}

                                        <Column field="actualTicketPrice" header="Ticket Price" sortField="ActualTicketPrice" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column>
                                        <Column field="isinwards" header="Inward / Outward" sortField="isinwards" sortable headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ overflowWrap: "anywhere" }} filter showFilterMenu={false} filterElement={IsInWardFilterTemplate}></Column>
                                        {/* <Column field="stop" header="Stop" sortField="Stop" sortable headerStyle={{ width: '10%', minWidth: '10rem' }}></Column> */}
                                        {hidecolumnComponents}
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>
                                </div>

                                <Dialog visible={flightDialog} draggable={false}
                                    style={{ width: '800px' }}
                                    // header={flight.id == null ? <span><i className="fa fa-plus-square mr-2"></i>Add Flight</span> : <span><i className="fa fa-edit mr-2"></i>Update Flight</span>}
                                    header="Flight Details"
                                    modal
                                    className="p-fluid"
                                    footer={FlightDialogFooter}
                                    onHide={hideDialog}
                                >

                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Flight No.</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText
                                                id="name"
                                                value={flight.flightNo}
                                                onChange={(e) => onInputChange(e, "flightNo")}
                                                required
                                                autoFocus
                                                placeholder="Enter FlightNo"
                                                className={classNames({ "p-invalid": submitted && !flight.flightNo })} />
                                            {submitted && !flight.flightNo && <small className="p-error">Flight no is required.</small>}
                                            {flightNo && (<small className="p-error">Flight no must be greater than 0 and less then and equal to 10.</small>)}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Boarding City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown
                                                value={boardingcitydropdowndata}
                                                options={boardingCity}
                                                optionLabel="name"
                                                filter
                                                placeholder={flight.cityName ? flight.cityName : "Select Boarding City"}
                                                onChange={(e) => { setboardingcitydropdowndata(e.target.value); onInputChange(e, "boardingCity") }}
                                                className={classNames({ "p-invalid": submitted && !flight.boardingCity })} />
                                            {submitted && !flight.boardingCity && <small className="p-error">Boarding city is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">From</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown
                                                value={flightfromdropdowndata}
                                                options={fromDropdown}
                                                optionLabel="name"
                                                placeholder={flight.from ? flight.from : "Select From City"}
                                                onChange={(e) => { setflightfromdropdowndata(e.target.value); onFromDropdownChange(e.value) }}
                                                className={classNames({ "p-invalid": submitted && !flight.from })}
                                            />
                                            {submitted && !flight.from && <small className="p-error">From airport is required.</small>}
                                            {fromToAirport && (<small className="p-error">From and to airport are not same.</small>)}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">To</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown
                                                value={citydropdowndata}
                                                options={allCity}
                                                optionLabel="name"
                                                filter
                                                placeholder={flight.to ? flight.to : "Select To City"}
                                                onChange={(e) => { setcitydropdowndata(e.target.value); onToDropdownChange(e.value) }}
                                                className={classNames({ "p-invalid": submitted && !flight.to })}
                                            />
                                            {submitted && !flight.to && <small className="p-error">To airport is required.</small>}
                                            {fromToAirport && (<small className="p-error">From and to airport are not same.</small>)}
                                        </div>

                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Depart On Date</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Calendar
                                                showIcon={true}
                                                id="time24"
                                                value={null}
                                                minDate={new Date()}
                                                placeholder={flight.id == null ? "Select Depart On" : flight.departOn ? moment(flight.departOn).format('DD-MM-YYYY') : "Select Depart On"}
                                                className={submitted && flight.departOn == Date ? "p-invalid" : ''}
                                                onChange={(e) => onInputChange(e, "departOn")}
                                                dateFormat="dd-mm-yy"
                                            />
                                            {/* {submitted && !flight.departOn && flight.departOn == Date && <small className="p-error">Please select departure date.</small>} */}
                                            {submitted && flight.departOn == Date.toString() && <small className="p-invalid">Please select departure date.</small>}
                                            {/* flight.departOn == Date */}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Depart On Time</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Calendar id="time12" value={flight.departOnTime} onChange={(e) => onInputChange(e, "departOnTime")} timeOnly
                                                placeholder={flight.id == null ? "Select Depart On Time" : flight.departOn ? moment(flight.departOn).format('HH:mm') : "select Depart On Time"}
                                                className={classNames({ "p-invalid": submitted && flight.departOnTime == Date })}
                                                hourFormat="24" />
                                            {submitted && flight.departOnTime == Date && <small className="p-error">Please fill departure time.</small>}
                                            {departArriveTime && (<small className="p-error">Depart on time and arrive at time does not same.</small>)}
                                            {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                        </div>

                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Arrive At</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Calendar
                                                showIcon
                                                id="time24"
                                                value={null}
                                                minDate={new Date()}
                                                placeholder={flight.id == null ? "Select Arrive at" : flight.arriveAt ? moment(flight.arriveAt).format('DD-MM-YYYY') : "Select Arrive At"}
                                                className={classNames({ "p-invalid": submitted && flight.arriveAt == Date })}
                                                onChange={(e) => onInputChange(e, "arriveAt")}
                                                dateFormat="dd-mm-yy"
                                            />
                                            {submitted && flight.arriveAt == Date && <small className="p-error">Please select arrival date.</small>}
                                            {departArriveDate && (<small className="p-error">Arrival at date not less than depart on date.</small>)}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Select Arrive At Time</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Calendar id="time12" value={flight.arriveAtTime} onChange={(e) => onInputChange(e, "arriveAtTime")} timeOnly
                                                placeholder={flight.id == null ? "Select Arrived At Time" : flight.arriveAt ? moment(flight.arriveAt).format('HH:mm') : "Select Arrive At Time"}
                                                className={classNames({ "p-invalid": submitted && flight.arriveAtTime == Date })}
                                                hourFormat="24" />
                                            {submitted && flight.arriveAtTime == Date && <small className="p-error">Please fill arrival time.</small>}
                                            {departArriveTime && (<small className="p-error">Depart on time and arrive at time does not same.</small>)}
                                            {arriveDepartTime && (<small className="p-error">Arrive at time not less than depart on time.</small>)}
                                        </div>

                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="name">Airline Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown
                                                value={airlinedropdowndata}
                                                options={allAirline}
                                                optionLabel="name"
                                                filter
                                                placeholder={flight.airlineId == null ? "Select Airline" : flight.airlineName}
                                                onChange={(e) => { setairlinedropdowndata(e.target.value); onAirlineDropdownChange(e.value) }}
                                                className={classNames({ "p-invalid": submitted && !flight.airlineName })}
                                            />
                                            {submitted && !flight.airlineName && <small className="p-error">Airline is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12"></div>
                                    </div>
                                </Dialog>

                                <Dialog visible={deleteFlightDialog} draggable={false} style={{ width: "450px" }} header="Confirm" modal footer={deleteFlightDialogFooter} onHide={hideDeleteFlightDialog}>
                                    <div className="flex align-items-center">
                                        <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                                        {flight && (
                                            <span>Are you sure you want to delete <b>{flight.flightNo}</b> flight?</span>
                                        )}
                                    </div>
                                </Dialog>

                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    );
}
export default Flight;