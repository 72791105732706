import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Checkbox } from "primereact/checkbox";
import { Paginator } from 'primereact/paginator';
import classNames from 'classnames';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import * as Zones from "../../enums/Zones";

const City = () => {

    let emptyCity = {
        id: '',
        name: '',
        stateId: '',
        stateName: '',
        countryId: '',
        countryName: '',
        isBoarding: false,
        zone: '',
    };

    // Hooks
    const [cityList, setCityList] = useState([]);
    const [id, setid] = useState('')
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [namedropdown, setnamedropdown] = useState([]);
    const [city, setCity] = useState(emptyCity);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [countryFilter, setcountryFilter] = useState("");
    const [StateFilter, setStateFilter] = useState("");
    const [cityFilter, setcityFilter] = useState("");
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [statedropdowndata, setstatedropdowndata] = useState(null);
    const [zonedropdown, setzonedropdown] = useState(null);

    // Flags
    const [AddnewDialog, setAddnewDialog] = useState(false);    // Adding New Country
    const [deleteDialog, setdeleteDialog] = useState(false);  // Deleting New Country
    const [checked, setChecked] = useState(false);    // Checkbox
    const [submitted, setSubmitted] = useState(false);
    const [enableFilter, setEnableFilter] = useState(false);
    const [isExportExcel, setIsExportExcel] = useState(false);

    // Const
    const UserName = localStorage.getItem('Username');
    // const date = new Date();
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        city: null,
        state: null,
        country: null,
        zone:null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getcitylist();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    // - All Api

    // Get All City
    const getcitylist = (isExportExcel = false, event) => {
        setIsLoading(true);

        //Zone
        var listZoneFilterString = null;
        if (lazyState.current.zone != null && lazyState.current.zone != undefined && lazyState.current.zone != '') {
            if (lazyState.current.zone.length > 0) {
                if (lazyState.current.zone.length > 1) {
                    listZoneFilterString = lazyState.current.zone.map(item => item.name).join(',');
                } else {
                    listZoneFilterString = lazyState.current.zone.map(item => item.name)[0];
                }
            }
        }

        if (!isExportExcel) setCityList([]);
        axios.post('/api/City/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "CityName",
            city: lazyState.current.city ? lazyState.current.city.trim() : null,
            state: lazyState.current.state ? lazyState.current.state.trim() : null,
            country: lazyState.current.country ? lazyState.current.country.trim() : null,
            Zone: listZoneFilterString ? listZoneFilterString : null,
        }).then(Response => {
            if (!isExportExcel) setCityList([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // CityId: dataObj.id,
                    Name: dataObj.cityName,
                    stateName: dataObj.stateName,
                    Country: dataObj.countryName,
                    Isboarding: dataObj.boardingCityornot
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // CityId: dataObj.id,
                        Name: dataObj.cityName,
                        stateName: dataObj.stateName,
                        Country: dataObj.countryName,
                        Isboarding: dataObj.boardingCityornot
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'City');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setCityList(Response.data.data);
            getStateList();
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Get All State In Dropdown
    const getStateList = () => {
        axios.post('/api/State/GetAll', {
            paginationRequired: false
        }).then(Response => {
            let data = [];
            for (const dataObj of Response.data.data) {
                data.push({ name: dataObj.stateName, code: dataObj.id, countryidcode: dataObj.countryId, countryName: dataObj.countryName, zone: dataObj.zone });
            };
            setnamedropdown(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Get By Id for cityList
    const getcitybyid = (id) => {
        setIsLoading(true);
        axios.get('/api/City/GetAsync/' + id)
            .then((Response) => {
                setid(Response.data.id);
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Add New City
    const Insertupdatecity = () => {
        setSubmitted(true);
        if (city.name.trim()) {
            if (city.id == '') {
                const url = '/api/City/Insert';
                const data = {
                    "Name": city.name.trim(),
                    "StateId": city.stateId,
                    "Isboarding": city.isBoarding,
                    "CreatedBy": UserName,
                    // "Created": date,
                }
                axios.post(url, data)
                    .then(() => {
                        setCity(emptyCity);
                        setstatedropdowndata(null);
                        setAddnewDialog(false);
                        getcitylist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New City Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: City With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'City Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (city.id) {
                const url = '/api/City/Update';
                const data = {
                    "id": city.id,
                    "Name": city.name.trim(),
                    "StateId": city.stateId,
                    "Isboarding": city.isBoarding,
                    "CreatedBy": CreatedBy,
                    "Created": Created,
                    "ModifiedBy": UserName,
                    // "Modified": date
                };
                axios.put(url, data)
                    .then(() => {
                        setCity(emptyCity);
                        setstatedropdowndata(null);
                        setAddnewDialog(false);
                        getcitylist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'City Updated', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: City With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'City Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    // To Delete City
    const Deletecity = () => {
        setIsLoading(true);
        setCity(emptyCity);
        axios.delete('/api/City/Delete/' + id)
            .then((result) => {
                if (result.status === 200) {
                    setdeleteDialog(false);
                    getcitylist(isExportExcel, lazyState.current);
                    setIsLoading(false);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'City Deleted', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                };
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    // Functions

    // On Add New City
    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setCity(emptyCity);
        setSubmitted(false);
        setAddnewDialog(true);
    };

    // On Edit City
    const editProduct = (city) => {
        setAddnewDialog(true);
        getcitybyid(city.id);
        let _city = { ...city };
        _city['id'] = (city.id) || '';
        _city['name'] = (city.cityName) || '';
        _city['stateId'] = (city.stateId) || '';
        _city['stateName'] = (city.stateName) || '';
        _city['countryId'] = (city.countryId) || '';
        _city['countryName'] = (city.countryName) || '';
        _city['isBoarding'] = (city.isBoarding);
        _city['zone'] = (city.zone) || '';
        setChecked(city.isBoarding);
        setCity(_city);
    };

    // On Delete City
    const confirmDeleteProduct = (city) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setdeleteDialog(true);
        setCity(city);
        getcitybyid(city.id);
    };

    // On Hide Dialog For Edit / Add City
    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setAddnewDialog(false);
        setSubmitted(false);
        setCity(emptyCity);
        setstatedropdowndata(null);
    };

    // On Hide Delete Dialog
    const hideDeleteProductDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setdeleteDialog(false);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getcitylist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getcitylist(isExportExcel, lazyState.current);
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // All Buttons

    // Edit And Delete Buttons
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:city"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editProduct(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
                <AccessControl
                    allowedPermissions={["delete:city"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteProduct(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Modals Button To Add City
    const productDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={() => Insertupdatecity(id)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { setAddnewDialog(false); hideDialog(e) }} outlined />
        </>
    );

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => Deletecity(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideDeleteProductDialog(false)} outlined />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:city"]}>
                <div className="my-2">
                    <Button label="Add City" icon="fa fa-plus" className="mr-2" onClick={openNew} outlined />
                </div>
            </AccessControl>
        );
    };

    const CityFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.city = e.target.value ? e.target.value : null
                getcitylist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.city = null
                getcitylist(isExportExcel, lazyState.current);
            };
        };
    };

    const StateFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.state = e.target.value ? e.target.value : null
                getcitylist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.state = null
                getcitylist(isExportExcel, lazyState.current);
            };
        };
    };

    const CountryFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.country = e.target.value ? e.target.value : null
                getcitylist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.country = null
                getcitylist(isExportExcel, lazyState.current);
            };
        };
    };

    const CityNameFilter = () => {
        return (

            <InputText name="cityname" value={cityFilter} placeholder="Enter City Name" onChange={(e) => setcityFilter(e.target.value)}
                onKeyDown={(e) => CityFilteronEnter(e)} style={{ width: '250px' }}></InputText>
        );
    };

    const StateNameFilter = () => {
        return (
            <InputText name="statename" value={StateFilter} placeholder="Enter State Name" onChange={(e) => setStateFilter(e.target.value)}
                onKeyDown={(e) => StateFilteronEnter(e)} style={{ width: '250px' }}></InputText>
        );
    };

    const CountryNameFilter = () => {
        return (
            <InputText name="countryname" value={countryFilter} placeholder="Enter Country Name" onChange={(e) => setcountryFilter(e.target.value)}
                onKeyDown={(e) => CountryFilteronEnter(e)} style={{ width: '250px' }}></InputText>
        );
    };

    // On Input Change
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value);
        let _city = { ...city };
        _city[`${name}`] = val;
        setCity(_city);
    };

    // On Dropdown Change
    const onDropdownChange = (e) => {
        let _city = { ...city };
        _city['stateId'] = (e.target && e.target.value.code);
        _city['stateName'] = (e.target && e.target.value.name);
        _city['countryId'] = (e.target && e.target.value.countryidcode);
        _city['countryName'] = (e.target && e.target.value.countryName);
        _city['zone'] = (e.target && e.target.value.zone);
        setCity(_city);
    };

    const removeFilter = () => {
        lazyState.current.city = null;
        setcityFilter(null);
        lazyState.current.state = null;
        setStateFilter(null);
        lazyState.current.country = null;
        setcountryFilter(null);
        getcitylist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.country == null && lazyState.current.state == null && lazyState.current.city == null ? true : false} icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className="mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2"
                    onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:city"]}>
                    <Button disabled={!(cityList != null && cityList.length > 0)} onClick={() => { getcitylist(true);; }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        )
    };

    const ZoneFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.zone}
                options={Zones.Zone}
                itemTemplate={ZoneFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => ZoneFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const ZoneFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const ZoneFilterBodyTemplate = (rowData) => {
        const zone = rowData.zone;
        return (
            <div className="flex align-items-center gap-2">
                <span>{zone}</span>
            </div>
        );
    };

    const ZoneFilterClick = (e) => {
        lazyState.current.zone = e.target.value ? e.target.value : null;
        getcitylist(isExportExcel, { Zone: e.value });
        e.preventDefault();
    }

    // Html Part
    return (
        <AccessControl
            allowedPermissions={["list:city"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-cog mr-2"></i>City</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" stripedRows ref={dt} value={cityList}
                                        lazy rows={lazyState.current.rows} scrollHeight="calc(100vh - 250px)" scrollable
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort dataKey="id" className="datatable-responsive" responsiveLayout="scroll" filterDisplay={"row"}>
                                        <Column field="cityName" sortField="CityName" header="Name" sortable filter filterElement={CityNameFilter} showFilterMenu={false} style={{ minWidth: '8rem' }}></Column>
                                        <Column field="zone" header="Zone" sortField="Zone" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem' }} showFilterMenu={false} filterElement={ZoneFilterTemplate} body={ZoneFilterBodyTemplate} filterField="Zone" filterMenuStyle={{ width: '14rem' }}></Column>
                                        <Column field="stateName" sortField="StateName" header="State" sortable filter filterElement={StateNameFilter} showFilterMenu={false} style={{ minWidth: '8rem' }}></Column>
                                        <Column field="countryName" sortField="CountryName" header="Country" sortable filter filterElement={CountryNameFilter} showFilterMenu={false} style={{ minWidth: '8rem' }}></Column>
                                        <Column field="boardingCityornot" sortField="BoardingCityornot" header="IsBoarding" sortable style={{ minWidth: '8rem' }}></Column>
                                        <Column header="Action" body={actionBodyTemplate} style={{ minWidth: '8rem' }}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    {/* For Adding New City */}
                                    <Dialog visible={AddnewDialog} draggable={false} style={{ width: '450px' }}
                                        //  header={city.id == '' ? <span><i className="fa fa-plus-square mr-2"></i>Add City</span> : <span><i className="fa fa-edit mr-2"></i>Update City</span>}
                                        header="City Details"
                                        modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                                        <div className="field">
                                            <label id="label" htmlFor="City">City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText maxlength="50" id="name" value={city.name} onChange={(e) => onInputChange(e, 'name')}
                                                placeholder="Enter City"
                                                required autoFocus className={classNames({ 'p-invalid': submitted && !city.name })} />
                                            {submitted && !city.name && <small className="p-invalid">Name is required.</small>}
                                        </div>

                                        <div className="field">
                                            <label id="label" htmlFor="State">State</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown id="state" filter value={statedropdowndata} onChange={(e) => { setstatedropdowndata(e.target.value); onDropdownChange(e) }}
                                                options={namedropdown} optionLabel="name" placeholder={city.stateId == '' ? "Select State" : city.stateName}
                                                className={classNames({ 'p-invalid': submitted && !city.stateName })} />
                                            {submitted && !city.stateName && <small className="p-invalid">State is required.</small>}
                                        </div>

                                        {/* For Autobinding Country */}
                                        <div className="field" >
                                            <label id="label" htmlFor="Country">Country</label>
                                            <InputText disabled value={city.countryName} placeholder="Enter Country" />
                                        </div>

                                        {/* For Autobinding Zone */}
                                        <div className="field" >
                                            <label id="label" htmlFor="Zone">Zone</label>
                                            <InputText disabled value={city.zone} placeholder="Enter zone" />
                                        </div>

                                        <div className="field">
                                            <Checkbox onChange={e => setCity({ ...city, isBoarding: e.checked })} checked={city.isBoarding} value={city.isBoarding} style={{ marginTop: "10px" }} ></Checkbox>
                                            <label id="label" htmlFor="IsBorading" className="ml-2" style={{ marginBottom: "4px" }}>Is boarding city?</label>
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>Are you sure you want to delete <b>{city.cityName}</b> city?</span>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )

}

export default City;