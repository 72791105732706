import React, { useEffect, useState } from 'react';
import '../../src/assets/layout/icard.css';
import Axios from 'axios';

const DownloadICard = (props) => {
    const [qrcodeimage, setqrcodeimage] = useState(null);

    useEffect(() => {
        GetQrCode();
        console.log(props.data.designation)
    }, [])

    const GetQrCode = () => {
        Axios.get(`/api/Participant/GetQrImage/${props.data.id}`).
            then(Response => {
                setqrcodeimage(Response.data);
            })
    }

    const capitalizeWords = (str) => {
        if (str.length === 0) {
            return ''; // Return empty string for empty input
        }
        return str
            .toLowerCase()
            .split(' ')
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    };

    return (
        <div className={props.data.designation == 'DELEGATE' ? 'bgimgdelegateicard' : (props.data.designation == 'INTERNATIONAL FACULTY' || props.data.designation == 'NATIONAL FACULTY') ? 'bgimgfacultyicard' : props.data.designation == 'CO-ORDINATOR' ? 'bgimgcoordinatoricard' : ''}>
            <div style={{ marginTop: "320px" }}>
                <h1 className='bgimgidcardH1'>{capitalizeWords(props.data.fullName)}</h1>
                <h2 className='bgimgidcardH2' >{capitalizeWords(props.data.cityname)} , {capitalizeWords(props.data.countryname)}</h2>
                <div className='bgimgidcardQR'>
                    {qrcodeimage && qrcodeimage.length > 0 ?
                        <center><img src={`data:image/jpg;base64,${qrcodeimage}`} width="260" height="260" /></center>
                        : ""
                    }</div>
            </div>
        </div>
    )
};

export default DownloadICard;

