import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { Toast } from 'primereact/toast';
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from 'primereact/inputtextarea';
import classNames from 'classnames';
import { Dropdown } from 'primereact/dropdown';
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useFormik } from 'formik'
import { Dialog } from 'primereact/dialog';
import * as Designations from '../../enums/Designations';
import * as Genders from '../../enums/Genders';
import * as Honorfics from '../../enums/Honorfics';
import * as Participanttypes from '../../enums/Participanttypes';
import * as Qualifications from '../../enums/Qualifications';
import * as Specialitys from '../../enums/Specialitys';
import * as Travelmodes from '../../enums/Travelmodes';
import * as Zones from '../../enums/Zones';
import * as CountryNoCode from '../../enums/CountryNoCode';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { Toolbar } from 'primereact/toolbar';
import { InputSwitch } from 'primereact/inputswitch';
import '../../../src/assets/layout/styling.css';
import moment from 'moment';
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import userDetails from "../../services/userTokenDetails";
import { Password } from 'primereact/password';
import { RadioButton } from 'primereact/radiobutton';

const AddParticipant = () => {

    let emptyparticipant = {
        id: '',
        honorfic: 'DR.',
        firstname: '',
        lastname: '',
        participanttype: 'DOCTOR',
        countryCode: '(+91)',
        countryCodeName: '',
        designation: 'DELEGATE',
        speciality: '',
        qualification: '',
        mbbsregistrationnumber: '',
        dmregistrationnumber: '',
        mobileno: '',
        email: '',
        phone: '',
        gender: '',
        countryid: '',
        countryname: '',
        stateid: '',
        statename: '',
        cityid: '',
        cityname: '',
        boardingcity: '',
        boardingcityname: '',
        zone: '',
        hospitalname: '',
        address: '',
        clinicphone: '',
        registrationstatus: '',
        travelmode_inbound: '',
        travelmode_outbound: '',
        needaccomodation: '',
        needaccomodationcode: null,
        participantremarks: '',
        isboarding: true,
        cardNo: '',
        present: null,
        hotelName: '',
        inFlightBookingStatus: '',
        outFlightBookingStatus: '',
        IsDirectReg: null,
        TricoConfirmed: '',
        Registeredby: '',
        Userid: '',
        companyid: null,
        Companyname: '',
        CompanyPhone:'',
        fullname: '',
        password: '',
        approveToSendParticipant:'',
        approveToSendTravelAgent:'',
        tricoremarks:''
    };

    const [participantdata, setparticipantdata] = useState(emptyparticipant);
    const [id, setid] = useState('');
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [country, setcountry] = useState([]);
    const [state, setstate] = useState([]);
    const [city, setcity] = useState([]);
    const [boardingcity, setboardingcity] = useState([]);
    const [Companylist, setCompanylist] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [flightdata, setFlightData] = useState([]);
    const [flightoutdata, setflightOutData] = useState([])
    const [ticketData, setTicketData] = useState([])
    const [titledropdown, settitledropdown] = useState(null);
    const [genderdropdown, setgenderdropdown] = useState(null);
    const [participanttypedropdown, setparticipanttypedropdown] = useState(null);
    const [countryCodedropdown, setcountryCodedropdown] = useState(null);
    const [countrydropdown, setcountrydropdown] = useState(null);
    const [zonedropdown, setzonedropdown] = useState(null);
    const [statedropdown, setstatedropdown] = useState(null);
    const [citydropdown, setcitydropdown] = useState(null);
    const [designationdropdown, setdesignationdropdown] = useState(null);
    const [qualificationdropdown, setqualificationdropdown] = useState(null);
    const [travelmodeindropdown, settravelmodeindropdown] = useState(null);
    const [travelmodeoutdropdown, settravelmodeoutdropdown] = useState(null);
    const [boardingcitydropdown, setboardingcitydropdown] = useState(null);
    const [companydropdown, setCompanydropdown] = useState(null);
    const [ApiHeaders, setApiHeaders] = useState(null);
    const [password, setpassword] = useState('');
    const [WhatsappStatus, setWhatsAppStatus] = useState(null);
    const [SeemamamNo, setSeemamamNo] = useState(null); 
    const [WhatsAppUrlData, setWhatsAppUrlData] = useState(null);
    const [batch, setbatch] = useState([]);
    var token = localStorage.getItem('access_token')

    // Flags
    const [submitted, setSubmitted] = useState(false);   //Checking Submitted Or Not
    const [addupdateflag, setaddupdateflag] = useState(false);  // For Dynamic Heading
    const [emaildisable, setEmaildisable] = useState(false);  // To Disable email while Update Record
    const [registersuccessmessage, setregistersuccessmessage] = useState(false);
    const [viewAttendeesFlag, setViewAttendeesFlag] = useState(false);
    const [addEditFlag, setAddEditFlag] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [zonedisabled, setZoneDisabled] = useState(true);
    const [statedisabled,setStateDisabled] = useState(true);
    const [citydisabled,setCityDisabled] = useState(true);
    const [isAnnonymousUser, setIsAnnonymousUser] = useState(false);
    const needAccomodation = [
        { name: 'Yes', key: true },
        { name: 'No', key: false }

    ];
    const [selectedneedaccomodation, setSelectedneedaccomodation] = useState(needAccomodation);
    const present = [
        { name: 'Yes', key: true },
        { name: 'No', key: false }
    ]
    const [selectedpresent, setselectedpresent] = useState(present);

    // Const
    const UserName = localStorage.getItem('Username');
    const Company = localStorage.getItem('Company');
    const CompanyId = localStorage.getItem('CompanyId')
    var usrDetails = userDetails();
    const date = new Date();
    const toast = useRef(null);
    const param = useParams();
    const history = useHistory();
    const location = useLocation();

    const countryCodeWithoutParentheses = participantdata.countryCode?.replace(/[()]/g, '');
    const WhatsAppSendNumber = countryCodeWithoutParentheses + participantdata.mobileno;
    const [designationdisabled, setDesignationdisabled] = useState(false);
    const [typedisabled, setTypedisabled] = useState(false);
    const [titledisabled, setTitledisabled] = useState(false);
    const [filteredQualifications, setFilteredQualifications] = useState([]);

    useEffect(() => {
        document.documentElement.style.setProperty('overflow', 'auto');
        getHeaders();
        if (param.id != '' && param.id != undefined) {
            getparticipantbyid(param.id);
            getFlightRecordbyId(param.id)
            getOutFlightRecordbyId(param.id)
            GetTicketDetails(param.id)
            getcompanylist();
            getBatchlist();
        }
        else {
            getcountrylist();
            if (usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN") {
                getcompanylist();
            }
        };
        if (usrDetails == null) {
            setIsAnnonymousUser(true);
        };

        setPermissionOnFieldBasedOnRole();
        getData();
    }, []);

    const setPermissionOnFieldBasedOnRole = () => {
        if (usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN") {
            const filteredQuals = Qualifications.Qualification;
            setFilteredQualifications(filteredQuals);
        }
        else {
            settitledropdown('Dr.');
            setparticipanttypedropdown('DOCTOR');
            setdesignationdropdown('DELEGATE');
            setDesignationdisabled(true);
            setTypedisabled(true);
            setTitledisabled(true);

            const filteredQuals = Qualifications.Qualification.filter((item) => item.code !== 'MBBS' && item.code !== 'SURGEON');
            setFilteredQualifications(filteredQuals);
        }
    };

    const getData = () => {
        fetch("config.json", {
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
            },
        })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setApiHeaders(data[1]);
                setWhatsAppUrlData(data[6].TicketWhatsAppUrl);
                setWhatsAppStatus(data[7].EnableWhatsApp);
                setSeemamamNo(data[8].SeemaMam);
            });
    };
    // Get All Country In Dropdown
    const getcountrylist = () => {
        setIsLoading(true);
        axios.get('/api/Country/CountryList').then(Response => {
            let data = []
            for (const dataObj of Response.data) {
                data.push({ name: dataObj.name, code: dataObj.id });
            };
            setcountry(data);
            setIsLoading(false);
            // getboardingcity();
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    const getcompanylist = () => {
        setIsLoading(true);
        axios.post('/api/Company/GetAll', {
            paginationRequired: false,
        }).then(Response => {
            let data = []
            for (const dataObj of Response.data.data) {
                data.push({ name: dataObj.name, code: dataObj.id,phone: dataObj.phone });
            };
            setCompanylist(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Get All State In Dropdown
    const getstatelist = (countryid, zone = null) => {
        axios.post('/api/State/StateList'
            , {
                paginationRequired: false,
                countryid: countryid,
                Zone: zone,
            }
        ).then(Response => {
            let data = []
            for (const dataObj of Response.data) {
                data.push({ name: dataObj.stateName, code: dataObj.id });
            };
            setstate(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Get All City In Dropdown
    const getcitylist = (stateid) => {
        axios.post('/api/City/CityList'
            , {
                paginationRequired: false,
                stateid: stateid
            }
        ).then(Response => {
            let data = []
            for (const dataObj of Response.data) {
                data.push({ name: dataObj.cityName, code: dataObj.id });
            };
            setcity(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

     // Get All Batch In DropDown
     const getBatchlist = () => {
        axios
            .post("/api/Batch/GetAll", {
                paginationRequired: false,
            })
            .then((Response) => {
                let data = [];
                for (const dataObj of Response.data.data) {
                    data.push({ name: dataObj.name, code: dataObj.id });
                }
                setbatch(data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: "error", summary: "Error", detail: "Oops! Something Went Wrong!", life: 3000 });
            });
    };

    // Get All BoardingCity In Dropdown
    const getboardingcity = (stateid) => {
        axios.post('/api/City/CityList'
            , {
                paginationRequired: false,
                // stateid: stateid,
                IsBoarding: true
            }
        ).then(Response => {
            let data = []
            for (const dataObj of Response.data) {
                data.push({ name: dataObj.cityName, code: dataObj.id });
            };
            setboardingcity(data);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
        });
    };

    // Add/Update Participant
    const InsertUpdateParticipant = () => {
        setSubmitted(true);
        if (participantdata.fullname.trim() && participantdata.gender.trim() && participantdata.countryid.trim() &&
            participantdata.stateid.trim() && participantdata.cityid.trim() && participantdata.mbbsregistrationnumber.trim() && participantdata.boardingcity.trim() &&
            participantdata.hospitalname.trim() && participantdata.email.trim() && participantdata.mobileno && participantdata.travelmode_inbound && participantdata.travelmode_outbound && participantdata.qualification && (formik.errors.email == '' || formik.errors.email == undefined) && (formik.errors.mobileno == '' || formik.errors.mobileno == undefined) &&
            (formik.errors.phone == '' || formik.errors.phone == undefined) && (formik.errors.clinicphone == '' || formik.errors.clinicphone == undefined) &&
            (formik.errors.cardNo == '' || formik.errors.cardNo == undefined) && (formik.errors.password == '' || formik.errors.password == undefined)) {
            setIsLoading(true);
            if (participantdata.id == '') {
                const url = '/api/Participant/RegisterAttendee';
                let splitname = participantdata.fullname?.split(' ');
                const passwordFullname = participantdata.fullname.replace(/\s/g, '');
                participantdata.password = (passwordFullname[0].toUpperCase() + (passwordFullname).substring(1, 2).toLowerCase() + "@" + (participantdata.mobileno).substring((participantdata.mobileno).length - 5)).toString()

                // participantdata.password = (participantdata.fullname[0].toUpperCase() + (participantdata.fullname).substring(1, 2).toLowerCase() + "@" + (participantdata.mobileno).substring((participantdata.mobileno).length - 5)).toString()
                const data = {
                    "participant": {
                        "Honorfic": participantdata.honorfic?.toUpperCase(),
                        "Firstname": participantdata.firstname?.toUpperCase(),
                        "Lastname": participantdata.lastname?.toUpperCase(),
                        "FullName": participantdata.fullname?.toUpperCase(),
                        "ParticipantType": participantdata.participanttype?.toUpperCase(),
                        "Designation": participantdata.designation?.toUpperCase(),
                        "speciality": Specialitys.Speciality[0].name?.toUpperCase(),
                        "Qualification": participantdata.qualification?.toUpperCase(),
                        "MBBSRegistrationNumber": participantdata.mbbsregistrationnumber?.toUpperCase(),
                        "DMRegistrationNumber": participantdata.dmregistrationnumber?.toUpperCase(),
                        "RegistrationStatus": "NEW",
                        "MobileNo": participantdata.countryCode + participantdata.mobileno?.replace(/[()]/g, ''),                      
                        "Email": participantdata.email?.toUpperCase(),
                        "Phone": participantdata.phone,
                        "CountryId": participantdata.countryid,
                        "Gender": participantdata.gender?.toUpperCase(),
                        "Zone": participantdata.zone?.toUpperCase(),
                        "StateId": participantdata.stateid,
                        "CityId": participantdata.cityid,
                        "BoardingCity": participantdata.boardingcity,
                        "Travelmode_Inbound": participantdata.travelmode_inbound?.toUpperCase(),
                        "Travelmode_Outbound": participantdata.travelmode_outbound?.toUpperCase(),
                        "NeedAccomodation": participantdata.needaccomodationcode ? participantdata.needaccomodationcode : false,
                        "HospitalName": participantdata.hospitalname?.toUpperCase(),
                        "Address": participantdata.address?.toUpperCase(),
                        "Clinicphone": participantdata.clinicphone,
                        "ParticipantRemarks": participantdata.participantremarks?.toUpperCase(),
                        "CreatedBy": UserName?.toUpperCase(),
                        "ApproveToSendTravelAgent": "PENDING",
                        "ApproveToSendParticipant": "PENDING",
                        "TicketBooked": false,
                        "IsDirectReg": (usrDetails && CompanyId != null) ? false : true,
                        "Registeredby": usrDetails ? usrDetails.sub : null,
                        "Password": participantdata.password,
                        "TricoRemarks":participantdata.tricoremarks?.toUpperCase()
                    },
                    "firstName": splitname.join(' ').toUpperCase(),
                    "lastName": splitname.pop().toUpperCase(),
                    "email": participantdata.email.trim().toUpperCase(),
                    "phoneNumber": participantdata.countryCode + participantdata.mobileno?.replace(/[()]/g, ''),
                    "password": participantdata.password,
                    "confirmPassword": participantdata.password,
                    "role": "DOCTOR",
                    "company": usrDetails?.role == "MR" || usrDetails?.role == "SUBMR" ? CompanyId : usrDetails?.role == "ADMIN" || usrDetails?.role == "ITADMIN" ? participantdata.companyid : null,
                    "CompanyPhone":participantdata.CompanyPhone

                };
                axios.post(url, data)
                    .then((response) => {
                        if (WhatsappStatus == true) {
                            SendWhatsAppText(response.data.participant.id);
                            SendWhatsAppToSeemaMam(response.data.participant);
                            SendWhatsAppToCompany(participantdata);
                        }
                        if (usrDetails == '' || usrDetails == undefined || usrDetails == null) {
                            setregistersuccessmessage(true);
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            if (usrDetails.role == 'SUBMR') {
                                history.push({ pathname: '/home/viewallattendees', state: { detail: "AddAttendee" } });
                            }
                            else {
                                history.push({ pathname: '/home/viewnewattendees', state: { detail: "AddAttendee" } });                            
                            };
                        };
                    }).catch((error) => {
                        setIsLoading(false);
                        if (error.response.data.includes("System.Exception: Registration Limit Exceed For All Participant Type.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Registration Limit Exceed For All Participant Type.', life: 4000 });
                        }
                        else if (error.response.data.includes("System.Exception: Participant With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Participant With This Email Already Exist.', life: 4000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (participantdata.id) {
                const url = '/api/participant/UpdateAttendeeAndCompany';
                const data = {
                    "Id": participantdata.id,
                    "Honorfic": participantdata.honorfic?.toUpperCase(),
                    "Firstname": participantdata.firstname?.toUpperCase(),
                    "Lastname": participantdata.lastname?.toUpperCase(),
                    "FullName": participantdata.fullname?.toUpperCase(),
                    "ParticipantType": participantdata.participanttype?.toUpperCase(),
                    "Designation": participantdata.designation?.toUpperCase(),
                    "speciality": Specialitys.Speciality[0].name?.toUpperCase(),
                    "Qualification": participantdata.qualification?.toUpperCase(),
                    "MBBSRegistrationNumber": participantdata.mbbsregistrationnumber?.toUpperCase(),
                    "DMRegistrationNumber": participantdata.dmregistrationnumber?.toUpperCase(),
                    "RegistrationStatus": participantdata.registrationstatus?.toUpperCase(),
                    "MobileNo": participantdata.countryCode + participantdata.mobileno?.replace(/[()]/g, ''),
                    // "MobileNo": participantdata.mobileno,
                    "Email": participantdata.email?.toUpperCase(),
                    "Phone": participantdata.phone?.toUpperCase(),
                    "Gender": participantdata.gender?.toUpperCase(),
                    "CountryId": participantdata.countryid,
                    "Zone": participantdata.zone?.toUpperCase(),
                    "StateId": participantdata.stateid,
                    "CityId": participantdata.cityid,
                    "BoardingCity": participantdata.boardingcity,
                    "Travelmode_Inbound": participantdata.travelmode_inbound?.toUpperCase(),
                    "Travelmode_Outbound": participantdata.travelmode_outbound?.toUpperCase(),
                    "InFlightBookingStatus": participantdata.inFlightBookingStatus?.toUpperCase(),
                    "OutFlightBookingStatus": participantdata.outFlightBookingStatus?.toUpperCase(),
                    "InBatchBooking": participantdata.inBatchBookingId,
                    "OutBatchBooking": participantdata.outBatchBookingId,
                    "TravelRemarks": participantdata.travelRemarks?.toUpperCase(),
                    "NeedAccomodation": participantdata.needaccomodationcode,
                    "HospitalName": participantdata.hospitalname?.toUpperCase(),
                    "Address": participantdata.address?.toUpperCase(),
                    "Clinicphone": participantdata.clinicphone,
                    "ParticipantRemarks": participantdata.participantremarks?.toUpperCase(),
                    "CreatedBy": CreatedBy,
                    "Created": Created,
                    "ModifiedBy": UserName,
                    "Modified": date,
                    "cardNo": participantdata.cardNo,
                    "present": participantdata.present,
                    "ApproveToSendTravelAgent": participantdata.approveToSendTravelAgent,
                    "ApproveToSendParticipant": participantdata.approveToSendParticipant,
                    "TicketBooked": false,
                    "TricoConfirmed": participantdata.TricoConfirmed,
                    "IsDirectReg":participantdata.companyid  ? false : true,
                    "Registeredby": participantdata.Registeredby,
                    "Userid": participantdata.Userid,
                    "Password": participantdata.password,
                    "Company": participantdata.companyid ? participantdata.companyid : null,
                    "TricoRemarks":participantdata.tricoremarks?.toUpperCase()
                };
                axios.put(url, data)
                    .then(() => {
                        if (UserName == '' || UserName == undefined || UserName == null) {
                            setregistersuccessmessage(true)
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            history.goBack();
                            toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Participant Updated', life: 3000 });
                        };
                    }).catch((error) => {
                        setIsLoading(false);
                        if (error.response.data.includes("System.Exception: Registration Limit Exceed For All Participant Type.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Registration Limit Exceed For All Participant Type.', life: 5000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    const getparticipantbyid = (id) => {
        setIsLoading(true);
        axios.get('/api/Participant/GetParticipantById/' + id)
            .then((Response) => {
                if (location.pathname.includes("viewparticipant")) {
                    setDisabled(true);
                    setViewAttendeesFlag(true);
                    setAddEditFlag(true);
                };
                setaddupdateflag(true);
                if (location.pathname.includes("editparticipant")) {
                    setaddupdateflag(true);
                };
                setEmaildisable(true)
                setid(Response.data.id);
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                let _participant = { ...Response.data };
                _participant['id'] = (Response.data.id);
                _participant['honorfic'] = (Response.data.honorfic);
                _participant['firstname'] = (Response.data.firstname);
                _participant['lastname'] = (Response.data.lastname);
                _participant['fullname'] = (Response.data.namewithsurname);
                _participant['participanttype'] = (Response.data.participantType);
                _participant['designation'] = (Response.data.designation);
                _participant['speciality '] = (Response.data.speciality);
                _participant['qualification'] = (Response.data.qualification);
                _participant['mbbsregistrationnumber'] = (Response.data.mbbsRegistrationNumber);
                _participant['dmregistrationnumber'] = (Response.data.dmRegistrationNumber);
                // _participant['mobileno'] = (Response.data.mobileNo);
                _participant['mobileno'] = (Response.data.mobileNo.split(")")[1]);
                _participant['countryCode'] = (Response.data.mobileNo.split(")")[0] + ")");
                _participant['email'] = (Response.data.email);
                _participant['phone'] = (Response.data.phone);
                _participant['gender'] = (Response.data.gender);
                _participant['countryid'] = (Response.data.countryid);
                _participant['stateid'] = (Response.data.stateid);
                _participant['cityid'] = (Response.data.cityid);
                _participant['boardingcity'] = (Response.data.boardingcityid);
                _participant['zone'] = (Response.data.zone);
                _participant['hospitalname'] = (Response.data.hospitalName);
                _participant['address'] = (Response.data.address);
                _participant['clinicphone'] = (Response.data.clinicphone);
                _participant['travelmode_inbound'] = (Response.data.travelmode_Inbound);
                _participant['travelmode_outbound'] = (Response.data.travelmode_Outbound);
                _participant['inFlightBookingStatus'] = (Response.data.inFlightBookingStatus);
                _participant['outFlightBookingStatus'] = (Response.data.outFlightBookingStatus);
                _participant['outBatchBooking'] = (Response.data.outBatchBooking);
                _participant['inBatchBooking'] = (Response.data.inBatchBooking);
                _participant['travelRemarks'] = (Response.data.travelRemarks);
                _participant['needaccomodation'] = (Response.data.accomodation);
                _participant['needaccomodationcode'] = (Response.data.needAccomodation);
                _participant['participantremarks'] = (Response.data.participantRemarks);
                _participant['registrationstatus'] = (Response.data.registrationStatus);
                _participant['IsDirectReg'] = (Response.data.isDirectReg);
                _participant['Companyname'] = (Response.data.company);
                _participant['companyid'] = (Response.data.companyId);
                _participant['CompanyPhone'] = (Response.data.companyPhone);
                _participant['Userid'] = (Response.data.userid);
                _participant['Registeredby'] = (Response.data.registeredby);
                _participant['TricoConfirmed'] = (Response.data.tricoConfirmed);
                _participant['password'] = (Response.data.password);
                _participant['present'] = (Response.data.present);
                _participant['approveToSendParticipant'] = (Response.data.approveToSendParticipant);
                _participant['approveToSendTravelAgent'] = (Response.data.approveToSendTravelAgent);
                _participant['tricoremarks'] = (Response.data.tricoRemarks);
                
                setparticipantdata(_participant);
                if (_participant) {
                    getcountrylist();
                    getstatelist(_participant.countryid, _participant.zone);
                    getcitylist(_participant.stateid);
                    getboardingcity(_participant.stateid);
                    getBatchlist();
                };
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // FUNCTIONS

    // On Input Change
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _participant = { ...participantdata };
        _participant[`${name}`] = val;
        if(name === 'TricoConfirmed'){
            _participant.TricoConfirmed = e.target.value.name
        }
        setparticipantdata(_participant);
    };

    // On Dropdown Change
    const onDropdownChange = (e, name) => {
        let _participant = { ...participantdata };
        if (name === 'needaccomodation' || name === 'countryid' || name === 'stateid' || name === 'Company' || name === 'cityid' ||
            name === 'boardingcity' || name === 'travelmode_inbound' || name === 'travelmode_outbound' || name === 'present'
            || name === 'countryCode' || name === 'zone') {
            if (name === 'needaccomodation') {
                _participant['needaccomodation'] = (e.target && e.target.value);
                _participant['needaccomodationcode'] = (e.target && e.target.value);
                setparticipantdata(_participant);
            };
            if (name === 'countryid') {
                if (e.target.value.name.toLowerCase() == 'india') {
                    setZoneDisabled(false)
                    setStateDisabled(false);
                    setCityDisabled(false);
                    _participant['zone'] = '';
                    //setparticipantdata(_participant);
                };

                if (e.target.value.name.toLowerCase() != 'india') {
                    setZoneDisabled(true)
                    setStateDisabled(false);
                    setCityDisabled(false);
                    _participant['zone'] = 'INTERNATIONAL';
                    //setparticipantdata(_participant);
                };

                _participant['countryid'] = (e.target && e.target.value.code);
                _participant['countryname'] = (e.target && e.target.value.name);
                _participant['stateid'] = '';
                _participant['statename'] = '';
                _participant['cityid'] = '';
                _participant['cityname'] = '';
                _participant['boardingcity'] = '';
                _participant['boardingcityname'] = '';
                setparticipantdata(_participant);
                if(_participant['zone']){
                    getstatelist(e.target.value.code, _participant['zone']);
                }
                setzonedropdown(_participant['zone']);
                setstate('');
                setcity('');
                setboardingcity('');
            };

            if (name === 'zone') {
                _participant['stateid'] = '';
                _participant['statename'] = '';
                _participant['cityid'] = '';
                _participant['cityname'] = '';
                _participant['boardingcity'] = '';
                _participant['boardingcityname'] = '';
                if(_participant['countryname'].toLowerCase() == 'india' && e.target.value.name.toLowerCase() == 'international')
                {
                    _participant['zone'] = '';
                    setzonedropdown(_participant['zone']);
                } else {
                    _participant['zone'] = (e.target && e.target.value.name);
                }
                setparticipantdata(_participant);
                getstatelist(_participant['countryid'], e.target.value.name);
                setcity('');
                setboardingcity('');
            };


            if (name === 'stateid') {
                _participant['stateid'] = (e.target && e.target.value.code);
                _participant['statename'] = (e.target && e.target.value.name);
                _participant['cityid'] = '';
                _participant['cityname'] = '';
                _participant['boardingcity'] = '';
                _participant['boardingcityname'] = '';
                setparticipantdata(_participant);
                getcitylist(e.target.value.code);
                getboardingcity(e.target.value.code);
            };

            if (name === 'cityid') {
                _participant['cityid'] = (e.target && e.target.value.code);
                _participant['cityname'] = (e.target && e.target.value.name);
                setparticipantdata(_participant);
            };
            if (name === 'countryCode') {
                _participant['countryCode'] = (e.target && e.target.value.code);
                _participant['countryCodeName'] = (e.target && e.target.value.name);
                setparticipantdata(_participant);
            }

            if (name === 'Company') {
                _participant['companyid'] = (e.target && e.target.value.code);
                _participant['companyname'] = (e.target && e.target.value.name);
                _participant['CompanyPhone'] = (e.target && e.target.value.phone);
                setparticipantdata(_participant);
            };

            if (name === 'boardingcity') {
                _participant['boardingcity'] = (e.target && e.target.value.code);
                _participant['boardingcityname'] = (e.target && e.target.value.name);
                setparticipantdata(_participant);
            };

            if (name === 'travelmode_inbound') {
                _participant['travelmode_inbound'] = (e.target && e.target.value.code);
                // _participant['boardingcityname'] = (e.target && e.target.value.name);
                setparticipantdata(_participant);
            };

            if (name === 'travelmode_outbound') {
                _participant['travelmode_outbound'] = (e.target && e.target.value.code);
                // _participant['boardingcityname'] = (e.target && e.target.value.name);
                setparticipantdata(_participant);
            };
            if (name === 'present') {
                _participant['present'] = (e.target && e.target.value);
                setparticipantdata(_participant);
            };
        }
        else {
            const val = (e.target && e.target.value.name) || '';
            let _participant = { ...participantdata };
            _participant[`${name}`] = val;
            setparticipantdata(_participant);
        };
    };

    //Flight Record for to event city
    const getFlightRecordbyId = (id) => {
        axios.post('/api/ParticipantFlight/GetParticipantFlightRecordById/' + id, {
            paginationRequired: false,
            in_Out: true
        }).then(Response => {
            setFlightData(Response.data);
        });
    };

    //Flight Record for From event city
    const getOutFlightRecordbyId = (id) => {
        axios.post('/api/ParticipantFlight/GetParticipantFlightRecordById/' + id, {
            paginationRequired: false,
            in_Out: false
        }).then(Response => {
            setflightOutData(Response.data);
        });
    };

    //Ticket details
    const GetTicketDetails = (id) => {
        axios.post('/api/Participant/GetTicketNameByParticipantId?id=' + id)
            .then(Response => {
                setTicketData(Response.data);
            }).catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    const DownloadFile = (ticketpath) => {
        const headers = {
            "Accept-Type": "application/octet-stream",
            "Authorization": "Bearer " + token
        };
        axios.get('/api/Participant/files?filename=' + ticketpath, {
            headers: headers, credentials: "include", responseType: 'blob'
        }).then(
            Response => {
                var data = new Blob([Response.data], { type: "application/pdf" });
                const a = document.createElement('a');
                var csvURL = window.URL.createObjectURL(data);
                a.href = csvURL;
                a.download = ticketpath.split('/').pop();
                a.click();
                window.URL.revokeObjectURL(csvURL);
            }
        )
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Email And Phone Validation
    const validate = (participant) => {
        const errors = {};

        if (!participant.email) {
            errors.email = ''
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(participant.email)) {
            errors.email = 'Invalid email address'
        };
        if (!participant.mobileno) {
            errors.mobileno = ''
        } 
        const countryCode = participantdata.countryCode;
        const countryCodes = {
            "(+355)": /^\d{9}$/,  // Albania
            "(+213)": /^\d{9}$/,  // Algeria
            "(+1-684)": /^\d{7}$/,  // American Samoa
            "(+376)": /^\d{6}$/,  // Andorra
            "(+244)": /^\d{9}$/,  // Angola
            "(+264)": /^\d{7}$/,  // Anguilla           
            "(+374)": /^\d{8}$/,  // Armenia
            "(+297)": /^\d{7}$/,  // Aruba
            "(+61)": /^\d{9}$/,  // Australia
            "(+994)": /^\d{9}$/,  // Azerbaijan
            "(+1-242)": /^\d{7}$/,  // The Bahamas
            "(+973)": /^\d{8}$/,  // Bahrain
            "(+1-246)": /^\d{7}$/,  // Barbados
            "(+375)": /^\d{9}$/,  // Belarus
            "(+32)": /^\d{9}$/,  // Belgium
            "(+501)": /^\d{7}$/,  // Belize
            "(+229)": /^\d{8}$/,  // Benin
            "(+1-441)": /^\d{7}$/,  // Bermuda
            "(+975)": /^\d{8}$/,  // Bhutan
            "(+591)": /^\d{8}$/,  // Bolivia
            "(+387)": /^\d{8}$/,  // Bosnia and Herzegovina
            "(+267)": /^\d{7}$/,  // Botswana
            "(+55)": /^\d{11}$/,  // Brazil
            "(+1-284)": /^\d{7}$/,  // British Virgin Islands
            "(+673)": /^\d{7}$/,  // Brunei
            "(+359)": /^\d{9}$/,  // Bulgaria
            "(+226)": /^\d{8}$/,  // Burkina Faso
            "(+95)": /^\d{9}$/,  // Burma (Myanmar)
            "(+257)": /^\d{7}$/,  // Burundi
            "(+855)": /^\d{9}$/,  // Cambodia
            "(+237)": /^\d{9}$/,  // Cameroon
            "(+238)": /^\d{7}$/,  // Cape Verde
            "(+1-345)": /^\d{7}$/,  // Cayman Islands
            "(+236)": /^\d{9}$/,  // Central African Republic
            "(+235)": /^\d{8}$/,  // Chad
            "(+56)": /^\d{9}$/,  // Chile
            "(+86)": /^\d{11}$/,  // China (People's Republic of China)
            "(+61)": /^\d{9}$/,  // Christmas Island
            "(+61)": /^\d{9}$/,  // Cocos Islands            
            "(+269)": /^\d{7}$/,  // Comoros
            "(+682)": /^\d{5}$/,  // Cook Islands
            "(+506)": /^\d{8}$/,  // Costa Rica
            "(+225)": /^\d{8}$/,  // Côte d'Ivoire
            "(+385)": /^\d{9}$/,  // Croatia
            "(+53)": /^\d{8}$/,  // Cuba
            "(+599)": /^\d{7}$/,  // Curaçao
            "(+357)": /^\d{8}$/,  // Cyprus
            "(+420)": /^\d{9}$/,  // Czech Republic
            "(+243)": /^\d{9}$/,  // Democratic Republic of the Congo
            "(+45)": /^\d{8}$/,  // Denmark
            "(+253)": /^\d{7}$/,  // Djibouti
            "(+1-767)": /^\d{7}$/,  // Dominica
            "(+1-809)": /^\d{7}$/,  // Dominican Republic
            "(+670)": /^\d{7}$/,  // East Timor (Timor-Leste)
            "(+593)": /^\d{9}$/,  // Ecuador
            "(+503)": /^\d{8}$/,  // El Salvador
            "(+240)": /^\d{8}$/,  // Equatorial Guinea
            "(+291)": /^\d{7}$/,  // Eritrea
            "(+372)": /^\d{8}$/,  // Estonia
            "(+251)": /^\d{9}$/,  // Ethiopia
            "(+500)": /^\d{4}$/,  // Falkland Islands
            "(+298)": /^\d{6}$/,  // Faroe Islands
            "(+679)": /^\d{7}$/,  // Fiji
            "(+358)": /^\d{9}$/,  // Finland
            "(+33)": /^\d{9}$/,  // France
            "(+594)": /^\d{9}$/,  // French Guiana
            "(+689)": /^\d{6}$/,  // French Polynesia
            "(+241)": /^\d{7}$/,  // Gabon
            "(+220)": /^\d{7}$/,  // The Gambia
            "(+995)": /^\d{9}$/,  // Georgia
            "(+49)": /^\d{11}$/,  // Germany
            "(+233)": /^\d{9}$/,  // Ghana
            "(+350)": /^\d{7}$/,  // Gibraltar
            "(+299)": /^\d{6}$/,  // Greenland
            "(+1-473)": /^\d{7}$/,  // Grenada
            "(+590)": /^\d{9}$/,  // Guadeloupe
            "(+1-671)": /^\d{7}$/,  // Guam
            "(+502)": /^\d{8}$/,  // Guatemala
            "(+224)": /^\d{9}$/,  // Guinea
            "(+245)": /^\d{7}$/,  // Guinea-Bissau
            "(+592)": /^\d{7}$/,  // Guyana
            "(+509)": /^\d{8}$/,  // Haiti
            "(+504)": /^\d{8}$/,  // Honduras
            "(+852)": /^\d{8}$/,  // Hong Kong
            "(+36)": /^\d{9}$/,  // Hungary
            "(+354)": /^\d{7}$/,  // Iceland
            "(+964)": /^\d{8}$/,  // Iraq
            "(+353)": /^\d{9}$/,  // Ireland
            "(+972)": /^\d{9}$/,  // Israel
            "(+1-876)": /^\d{7}$/,  // Jamaica
            "(+962)": /^\d{9}$/,  // Jordan
            "(+254)": /^\d{9}$/,  // Kenya
            "(+686)": /^\d{7}$/,  // Kiribati
            "(+383)": /^\d{8}$/,  // Kosovo
            "(+965)": /^\d{8}$/,  // Kuwait
            "(+996)": /^\d{9}$/,  // Kyrgyzstan
            "(+856)": /^\d{8}$/,  // Laos
            "(+371)": /^\d{8}$/,  // Latvia
            "(+961)": /^\d{8}$/,  // Lebanon
            "(+266)": /^\d{7}$/,  // Lesotho
            "(+231)": /^\d{8}$/,  // Liberia
            "(+218)": /^\d{9}$/,  // Libya
            "(+423)": /^\d{7}$/,  // Liechtenstein
            "(+370)": /^\d{8}$/,  // Lithuania
            "(+352)": /^\d{8}$/,  // Luxembourg
            "(+389)": /^\d{8}$/,  // Macedonia
            "(+261)": /^\d{9}$/,  // Madagascar
            "(+265)": /^\d{9}$/,  // Malawi
            "(+960)": /^\d{7}$/,  // Maldives
            "(+223)": /^\d{8}$/,  // Mali
            "(+356)": /^\d{8}$/,  // Malta
            "(+692)": /^\d{7}$/,  // Marshall Islands
            "(+596)": /^\d{9}$/,  // Martinique
            "(+222)": /^\d{8}$/,  // Mauritania
            "(+230)": /^\d{8}$/,  // Mauritius
            "(+262)": /^\d{9}$/,  // Mayotte
            "(+691)": /^\d{7}$/,  // Federated States of Micronesia
            "(+373)": /^\d{8}$/,  // Moldova
            "(+377)": /^\d{8}$/,  // Monaco
            "(+976)": /^\d{8}$/,  // Mongolia
            "(+382)": /^\d{8}$/,  // Montenegro
            "(+1-664)": /^\d{7}$/,  // Montserrat
            "(+212)": /^\d{9}$/,  // Morocco
            "(+258)": /^\d{9}$/,  // Mozambique
            "(+374)": /^\d{9}$/,  // Nagorno-Karabakh
            "(+264)": /^\d{7}$/,  // Namibia
            "(+674)": /^\d{7}$/,  // Nauru
            "(+31)": /^\d{9}$/,  // Netherlands
            "(+687)": /^\d{6}$/,  // New Caledonia
            "(+64)": /^\d{9}$/,  // New Zealand
            "(+505)": /^\d{8}$/,  // Nicaragua
            "(+227)": /^\d{8}$/,  // Niger
            "(+683)": /^\d{4}$/,  // Niue
            "(+672)": /^\d{4}$/,  // Norfolk Island
            "(+850)": /^\d{8}$/,  // North Korea
            "(+1-670)": /^\d{7}$/,  // Northern Mariana Islands
            "(+47)": /^\d{8}$/,  // Norway
            "(+968)": /^\d{8}$/,  // Oman
            "(+680)": /^\d{7}$/,  // Palau
            "(+970)": /^\d{9}$/,  // Palestine
            "(+507)": /^\d{8}$/,  // Panama
            "(+675)": /^\d{7}$/,  // Papua New Guinea
            "(+595)": /^\d{9}$/,  // Paraguay
            "(+51)": /^\d{9}$/,  // Peru
            "(+872)": /^\d{4}$/,  // Pitcairn Islands
            "(+48)": /^\d{9}$/,  // Poland
            "(+351)": /^\d{9}$/,  // Portugal
            "(+1-787)": /^\d{7}$/,  // Puerto Rico
            "(+974)": /^\d{8}$/,  // Qatar
            "(+242)": /^\d{7}$/,  // Republic of the Congo
            "(+262)": /^\d{9}$/,  // Réunion
            "(+250)": /^\d{9}$/,  // Rwanda
            "(+212)": /^\d{9}$/,  // Sahrawi Arab Democratic Republic
            "(+590)": /^\d{9}$/,  // Saint Barthélemy
            "(+290)": /^\d{4}$/,  // Saint Helena, Ascension, and Tristan da Cunha
            "(+1-869)": /^\d{7}$/,  // Saint Kitts and Nevis
            "(+590)": /^\d{9}$/,  // Saint Martin
            "(+1-758)": /^\d{7}$/,  // Saint Lucia
            "(+508)": /^\d{7}$/,  // Saint Pierre and Miquelon
            "(+1-784)": /^\d{7}$/,  // Saint Vincent and the Grenadines
            "(+685)": /^\d{7}$/,  // Samoa
            "(+378)": /^\d{9}$/,  // San Marino
            "(+239)": /^\d{7}$/,  // São Tomé and Príncipe
            "(+966)": /^\d{9}$/,  // Saudi Arabia
            "(+221)": /^\d{9}$/,  // Senegal
            "(+381)": /^\d{9}$/,  // Serbia
            "(+248)": /^\d{7}$/,  // Seychelles
            "(+232)": /^\d{8}$/,  // Sierra Leone
            "(+65)": /^\d{8}$/,  // Singapore
            "(+1-721)": /^\d{7}$/,  // Sint Maarten
            "(+421)": /^\d{9}$/,  // Slovakia
            "(+386)": /^\d{8}$/,  // Slovenia
            "(+677)": /^\d{5}$/,  // Solomon Islands
            "(+252)": /^\d{7}$/,  // Somalia
            "(+963)": /^\d{9}$/,  // Syria
            "(+992)": /^\d{9}$/,  // Tajikistan
            "(+255)": /^\d{9}$/,  // Tanzania
            "(+66)": /^\d{9}$/,  // Thailand
            "(+228)": /^\d{8}$/,  // Togo
            "(+690)": /^\d{4}$/,  // Tokelau
            "(+676)": /^\d{4}$/,  // Tonga
            "(+380)": /^\d{9}$/,  // Ukraine
            "(+971)": /^\d{9}$/,  // United Arab Emirates
            "(+1-340)": /^\d{7}$/,  // United States Virgin Islands
            "(+598)": /^\d{8}$/,  // Uruguay
            "(+678)": /^\d{7}$/,  // Vanuatu
            "(+379)": /^\d{8}$/,  // Vatican City
            "(+967)": /^\d{9}$/,  // Yemen
            "(+263)": /^\d{9}$/,  // Zimbabwe
            "(+998)": /^\d{9}$/,  // Uzbekistan      
        };
        
        if (countryCodes[countryCode]) {
            if (!countryCodes[countryCode].test(participantdata.mobileno)) {
                errors.mobileno = 'Invalid mobile number';
            }
        } else {
            // "(+91)": /^\d{10}$/,  // India
            // "(+1)": /^\d{10}$/,   // USA, Canada, and other +1 countries
            // "(+7)": /^\d{10}$/,   // Russia
            // "(+81)": /^\d{10}$/,  // Japan
            // "(+44)": /^\d{10}$/,  // UK            
            // "(+20)": /^\d{10}$/,  // Egypt
            // "(+54)": /^\d{10}$/,  // Argentina
            // "(+43)": /^\d{10}$/,  // Austria
            // "(+880)": /^\d{10}$/,  // Bangladesh
            // "(+57)": /^\d{10}$/,  // Colombia
            // "(+30)": /^\d{10}$/,  // Greece
            // "(+44)": /^\d{10}$/,  // Guernsey
            // "(+62)": /^\d{10}$/,  // Indonesia
            // "(+98)": /^\d{10}$/,  // Iran
            // "(+44)": /^\d{10}$/,  // Isle of Man
            // "(+39)": /^\d{10}$/,  // Italy
            // "(+7)": /^\d{10}$/,  // Kazakhstan
            // "(+44)": /^\d{10}$/,  // Jersey
            // "(+60)": /^\d{10}$/,  // Malaysia
            // "(+52)": /^\d{10}$/,  // Mexico
            // "(+977)": /^\d{10}$/,  // Nepal
            // "(+234)": /^\d{10}$/,  // Nigeria
            // "(+90)": /^\d{10}$/,  // Northern Cyprus
            // "(+92)": /^\d{10}$/,  // Pakistan
            // "(+63)": /^\d{10}$/,  // Philippines
            // "(+40)": /^\d{10}$/,  // Romania
            // "(+886)": /^\d{10}$/,  // Taiwan            
            // "(+44)": /^\d{10}$/,  // United Kingdom
            // "(+1)": /^\d{10}$/,  // United States            
            // "(+58)": /^\d{10}$/,  // Venezuela
            // "(+84)": /^\d{10}$/,  // Vietnam            
            // "(+260)": /^\d{10}$/,  // Zambia
            if (!/^\d{10}$/.test(participantdata.mobileno)) {
                errors.mobileno = 'Invalid mobile number';
            }
        }
        
        if (!participant.phone) {
            errors.phone = ''
        } else if (! /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(participant.phone)) {
            errors.phone = 'Invalid phone number'
        };
        if (!participant.clinicphone) {
            errors.clinicphone = ''
        } else if (! /^(\+\d{1,2}\s?)?\(?\d{3,4}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/.test(participant.clinicphone)) {
            errors.clinicphone = 'Invalid phone number'
        };
        if (!participant.cardNo) {
            errors.cardNo = ''
        } else if (! /^[1-9]/.test(participant.cardNo)) {
            errors.cardNo = 'Invalid card number'
        };
        if (!password) {
            errors.password = ''
        } else if (! /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/g.test(password)) {
            errors.password = 'Password must be minimum 8 characters, contain at least one number, one special character and a mixture of upper and lower case letters'
        };
        return errors;
    };

    const formik = useFormik({
        initialValues: {
            email: '',
            mobileno: '',
            phone: '',
            clinicphone: '',
            cardNo: '',
            password: ''
        },
        validate,
        onSubmit: (participant) => {
            InsertUpdateParticipant(participant);
        },
    });

    //For Ok Button On Participant Register Success Dialog
    const footerContent = (
        <div>
            <Button label="OK" icon='fa fa-solid fa-check' onClick={(e) => window.location.assign('/')}></Button>
        </div>
    );

    const Redirect = () => {
        if (UserName == null || UserName == undefined || UserName == '') {
            window.location.assign('/');
        }
        else {
            history.goBack();
        };
    };

    const EditParticipant = (rowdataid) => {
        history.push('/home/editparticipant/' + rowdataid);
    };

    //Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button outlined icon="fa fa-arrow-left" onClick={(e) => Redirect(e)} tooltip="Back" tooltipOptions={{ position: 'bottom' }} />
                <AccessControl allowedPermissions={["edit:addparticipant"]}>
                    <i className={usrDetails && (usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN') || !addEditFlag ? "pi pi-bars p-toolbar-separator" : "hidden"}></i>
                    {addEditFlag ?
                        <Button label="Edit" icon="fa fa-pencil" tooltip="Edit" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className={(usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN')  ? "mr-2" : "hidden"} onClick={() => { EditParticipant(id); }} outlined />
                        : <Button label="Save" icon="fa fa-check" tooltip="Save" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="mr-2" onClick={() => { InsertUpdateParticipant(id); }} outlined />
                    }
                </AccessControl>
                {!isAnnonymousUser ? ""
                    : <>
                        <i class="pi pi-bars p-toolbar-separator"></i>
                        <Button label="Save" icon="fa fa-check" tooltip="Save" tooltipOptions={{ position: "bottom", className: "showtooltip" }} className="mr-2" onClick={() => { InsertUpdateParticipant(id); }} outlined />
                    </>
                }
            </div>
        );
    };

    const headertemplate = () => {
        return (
            <label className="success">Successful!</label>
        );
    };

    const getHeaders = () => {
        fetch('config.json'
            , {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }
        )
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                setApiHeaders(data[1]);
                setWhatsAppStatus(data[7].EnableWhatsApp)
            });
    };

    // For Whats App 
    // const header = {
    //     headers: {
    //         "Authorization": "Bearer EAAM1NMFIC4YBO1iJre5KdpJyh4ulfSzoMK7BS2qDNkoQXkUGly95vzSImVUa5HXWR00nkWYzavaPIG9RvKDVhyiZBN9VmGx71S92Wsn1FfVbKTwhUCqoJWh7zNvW7ijN5DtRGLDwi9FMskoNZBsAGpanTxOo5trIaFXwszjGGgJcZC0nGREk2IGhVyj76fXd8AMvQbZB9Utiw8XGmYRnbuNqMYsPUzkSZB6hCMJMZD",
    //         "Accept": "application/json"
    //     }
    // }

    const body = {
        "messaging_product": "whatsapp",
        "to": WhatsAppSendNumber,
        "type": "template",
        "template": {
            "name": "trico_register_template_utl",
            "language": {
                "code": "en"
            },
            "components": [
                {
                    "type": "header",
                    "parameters": [{
                        "type": "text",
                        "text": participantdata.honorfic.trim().toUpperCase() + ' ' + participantdata.fullname.trim().toUpperCase()
                    }]
                }]
        }
    };

    const SendWhatsAppText = (event) => {
        axios.post('https://graph.facebook.com/v17.0/177696368765013/messages', body, ApiHeaders)
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Whats App Message Sent SuccessFully', life: 3000 });
                WhatsAppLog(event);
            }).catch((error) => {
                console.log(error);
            });
    };

    const SendWhatsAppToSeemaMam = (element) => {
        const body = {
            "messaging_product": "whatsapp",
            "to": SeemamamNo,
            "type": "template",
            "template": {
                "name": "trico_register_template_utl",
                "language": {
                    "code": "en"
                },
                "components": [
                    {
                        "type": "header",
                        "parameters": [{
                            "type": "text",
                            "text": participantdata.honorfic.trim().toUpperCase() + ' ' + participantdata.fullname.trim().toUpperCase()
                        }]
                    }]
            }
        };
        axios
            .post("https://graph.facebook.com/v17.0/177696368765013/messages", body, ApiHeaders)
            .then((res) => {
                toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Whats App Message Sent to Seema Mam SuccessFully', life: 3000 });
                WhatsAppLog(body, element?.id);
            })
            .catch((error) => {
            });
    };

    const SendWhatsAppToCompany = (element) => {
        if(element.CompanyPhone != null) {
            const body = {
                "messaging_product": "whatsapp",
                "to": element.CompanyPhone,
                "type": "template",
                "template": {
                    "name": "trico_register_template_utl",
                    "language": {
                        "code": "en"
                    },
                    "components": [
                        {
                            "type": "header",
                            "parameters": [{
                                "type": "text",
                                "text": participantdata.honorfic.trim().toUpperCase() + ' ' + participantdata.fullname.trim().toUpperCase()
                            }]
                        }]
                }
            };
            axios
                .post("https://graph.facebook.com/v17.0/177696368765013/messages", body, ApiHeaders)
                .then((res) => {
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'Whats App Message Sent to Seema Mam SuccessFully', life: 3000 });
                    WhatsAppLog(body, element?.CompanyPhone);
                })
                .catch((error) => {
                });
        }
    };

    const WhatsAppLog = (event) => {
        const url = '/api/Participant/WhatsAppLog';
        const data = {
            Number:WhatsAppSendNumber,
            Template: "trico_register_template_utl",
            SendBy: UserName,
            AttendeeId:event,
            Screen: "ADD-ATTENDEES",
        }
        axios.post(url,data)
            .then((res) => {
            }).catch((error) => {
                console.log(error);
            });
    }

    return (
        <AccessControl allowedPermissions={viewAttendeesFlag ? ["view:addparticipant"] : ""} renderNoAccess={() => <NoAccess />}>
            <div className="trico-Form">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card" >
                                    {UserName == null ?
                                        <h5>
                                            <span>
                                                <i className={emaildisable ? `fa fa-edit mr-2` : `fa fa-plus-square mr-2`}></i>
                                                User Registration
                                            </span>
                                        </h5>
                                        :
                                        <h5>
                                            <span>
                                                <i className={addupdateflag ? viewAttendeesFlag ? `fa fa-binoculars mr-2` : `fa fa-edit mr-2` : `fa fa-plus-square mr-2`}></i>
                                                {addupdateflag ? viewAttendeesFlag ? `View Attendee` : `Update Attendee` : `Add Attendee`}
                                            </span>
                                        </h5>}
                                    <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>

                                    <div className="p-fluid formgrid grid">
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <hr className="hr-text" data-content="Personal Details"></hr>
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="email">Email</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText autoFocus value={participantdata.email?.toUpperCase()} disabled={emaildisable} required name="email" type="email"
                                                onBlur={formik.handleBlur} onChange={(e) => { onInputChange(e, 'email'); formik.handleChange(e) }}
                                                placeholder="abc@example.com"
                                                id={classNames({ 'p-invalid': submitted && !participantdata.email })} />
                                            {formik.touched.email && formik.errors.email && (<span className="p-invalid">{formik.errors.email}</span>)}
                                            {submitted && !participantdata.email && <small className="p-invalid">Email is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="honorfic">Title</label>
                                            <Dropdown value={titledropdown} required onChange={(e) => { settitledropdown(e.target.value); onDropdownChange(e, 'honorfic') }} options={Honorfics.honorfic} optionLabel="name"
                                                placeholder={participantdata.honorfic == '' ? "Select Title" : participantdata.honorfic} className="w-full " disabled={titledisabled || disabled} />
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="fullname">Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText value={participantdata.fullname?.toUpperCase()} required placeholder="Enter Name" onChange={(e) => onInputChange(e, 'fullname')}
                                                className={classNames({ 'p-invalid w-full': submitted && !participantdata.fullname.trim() })} disabled={disabled} maxLength="100" />
                                            {submitted && !participantdata.fullname && <small className="p-invalid">Name is required.</small>}

                                        </div>
                                        {/* <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Name">First Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText  value={participantdata.firstname?.toUpperCase()} required onChange={(e) => onInputChange(e, 'firstname')} placeholder="Enter First Name"
                                                // id={classNames({ 'p-invalid': submitted && !participantdata.firstname.trim() })}
                                                disabled={disabled} />
                                            {submitted && participantdata.firstname.length < 4 && <small className="p-invalid">Firstname must be at least 4 characters.</small>}
                                            {/* {submitted && !participantdata.firstname.trim() && <small className="p-invalid">Firstname is required</small>} */}
                                        {/* </div> */}
                                        {/* <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="lastname">Last Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText  value={participantdata.lastname?.toUpperCase()} onChange={(e) => onInputChange(e, 'lastname')} placeholder="Enter Last Name"
                                                // id={classNames({ 'p-invalid': submitted && !participantdata.lastname.trim() })}
                                                disabled={disabled} />
                                            {submitted && !participantdata.lastname.trim() && <small className="p-invalid">Lastname is required.</small>}
                                        </div> */}

                                        <div className="field col-12 md:col-2 sm:col-12">
                                            <label id="label" htmlFor="Gender">Gender</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown value={genderdropdown} required onChange={(e) => { setgenderdropdown(e.target.value); onDropdownChange(e, 'gender') }} options={Genders.Gender} optionLabel="name"
                                                className="w-full" placeholder={participantdata.gender == '' ? "Select Gender" : participantdata.gender}
                                                id={classNames({ 'p-invalid': submitted && !participantdata.gender })} disabled={disabled} />
                                            {submitted && !participantdata.gender && <small className="p-invalid">Gender is required.</small>}
                                        </div>

                                        <div className="field col-12 md:col-2 sm:col-12">
                                            <label id="label" htmlFor="ParticipantType">Type</label>
                                            <Dropdown value={participanttypedropdown} required onChange={(e) => { setparticipanttypedropdown(e.target.value); onDropdownChange(e, 'participanttype') }} options={Participanttypes.Participanttype} optionLabel="name"
                                                className="w-full " disabled={typedisabled || disabled} placeholder={participantdata.participanttype == '' ? "Select Attendees Type" : participantdata.participanttype} />
                                        </div>


                                        <div className="field col-12 md:col-2 sm:col-12">
                                            <label id="label" htmlFor="countrycode" >Country Code</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter value={countryCodedropdown} required onChange={(e) => { setcountryCodedropdown(e.target.value); onDropdownChange(e, 'countryCode') }} options={CountryNoCode.CountryNoCode} optionLabel="name"
                                                className="w-full " disabled={disabled} placeholder={participantdata.countryCode == '' ? "Select Country Code" : participantdata.countryCode} />
                                        </div>


                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="mobileno"> Mobile No.</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText value={participantdata.mobileno} required name="mobileno" maxLength="20"
                                                onBlur={formik.handleBlur} onChange={(e) => { onInputChange(e, 'mobileno'); formik.handleChange(e) }}
                                                id={classNames({ 'p-invalid': submitted && !participantdata.mobileno })} disabled={disabled} placeholder="Enter Mobile No." />
                                            {formik.touched.mobileno && formik.errors.mobileno && (<span className="p-invalid">{formik.errors.mobileno}</span>)}
                                            {submitted && !participantdata.mobileno && <small className="p-invalid">Mobileno is required.</small>}
                                        </div>

                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="phone">Phone No. </label>
                                            <InputText name="phone" value={participantdata.phone} required maxLength="20"
                                                onBlur={formik.handleBlur} onChange={(e) => { onInputChange(e, 'phone'); formik.handleChange(e) }} disabled={disabled} placeholder="Enter Phone No." />
                                            {formik.touched.phone && formik.errors.phone && (<span className="p-invalid">{formik.errors.phone}</span>)}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Country">Country</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter value={countrydropdown} required onChange={(e) => { setcountrydropdown(e.target.value); onDropdownChange(e, 'countryid') }} options={country} optionLabel="name"
                                                className="w-full" placeholder={participantdata.countryname == '' ? "Select Country" : participantdata.countryname}
                                                id={classNames({ 'p-invalid': submitted && !participantdata.countryname })} disabled={disabled} />
                                            {submitted && !participantdata.countryname && <small className="p-invalid">Country is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Zone">Zone</label>
                                            <Dropdown value={zonedropdown} required
                                                onChange={(e) => { setzonedropdown(e.target.value); onDropdownChange(e, 'zone') }}
                                                options={Zones.Zone} optionLabel="name" disabled={zonedisabled || disabled}
                                                placeholder={participantdata.zone == '' ? "Select Zone" : participantdata.zone} />
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="State">State</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter value={statedropdown} required onChange={(e) => { setstatedropdown(e.target.value); onDropdownChange(e, 'stateid') }} options={state} optionLabel="name"
                                                className="w-full" placeholder={participantdata.stateid == '' ? "Select State" : participantdata.statename}
                                                id={classNames({ 'p-invalid': submitted && !participantdata.statename })} disabled={statedisabled || disabled} />
                                            {submitted && !participantdata.statename && <small className="p-invalid">State is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="City">City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter value={citydropdown} required onChange={(e) => { setcitydropdown(e.target.value); onDropdownChange(e, 'cityid') }} options={city} optionLabel="name"
                                                className="w-full" placeholder={participantdata.cityname == '' ? "Select City" : participantdata.cityname}
                                                id={classNames({ 'p-invalid': submitted && !participantdata.cityname })} disabled={citydisabled ||disabled} />
                                            {submitted && !participantdata.cityname && <small className="p-invalid">City is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Designation">Designation</label>
                                            <Dropdown value={designationdropdown} required onChange={(e) => { setdesignationdropdown(e.target.value); onDropdownChange(e, 'designation') }} options={Designations.Designation} optionLabel="name"
                                                className="w-full " disabled={designationdisabled || disabled} placeholder={participantdata.designation == '' ? "Select Designation" : participantdata.designation} />
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Speciality">Speciality</label>
                                            <InputText value={Specialitys.Speciality[0].name} editable required onChange={(e) => onDropdownChange(e, 'speciality')} options={Specialitys.Speciality} optionLabel="name"
                                                className="w-full " disabled />
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Qualification">Qualification</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown value={qualificationdropdown} required onChange={(e) => { setqualificationdropdown(e.target.value); onDropdownChange(e, 'qualification') }} options={filteredQualifications} optionLabel="name"
                                                className="w-full "   id={classNames({ 'p-invalid': submitted && !participantdata.qualification })} disabled={disabled} placeholder={participantdata.qualification == '' ? "Select Qualification" : participantdata.qualification} />
                                            {submitted && !participantdata.qualification && <small className="p-invalid">Qualification is required.</small>}    
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Mbbsregnum">Degree Registration Number</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText value={participantdata.mbbsregistrationnumber?.toUpperCase()} required onChange={(e) => onInputChange(e, 'mbbsregistrationnumber')}
                                                // id={classNames({ 'p-invalid': submitted && !participantdata.mbbsregistrationnumber.trim() })} 
                                                disabled={disabled} placeholder="Degree Registration Number" maxLength="20" />
                                            {submitted && !participantdata.mbbsregistrationnumber.trim() && <small className="p-invalid">Degree Registration Number is required.</small>}
                                        </div>
                                        <div className={(usrDetails?.role == 'ADMIN' || usrDetails?.role == 'ITADMIN') ? 'field col-12 md:col-12 sm:col-12' : "hidden"}>
                                            <label id="label" htmlFor="TricoRemarks">Trico Remarks</label>
                                            <InputTextarea className="w-full" disabled={disabled} value={participantdata.tricoremarks?.toUpperCase()} required onChange={(e) => onInputChange(e, 'tricoremarks')}  placeholder="Enter Remarks" maxLength="500" />
                                        </div>
                                        {/* <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="Dmregnum">DM Registration Number</label>
                                            <InputText value={participantdata.dmregistrationnumber?.toUpperCase()} required onChange={(e) => onInputChange(e, 'dmregistrationnumber')} disabled={disabled} placeholder="DM Registration Number" maxLength="20" />
                                        </div> */}
                                        <div className={(usrDetails?.role == 'ADMIN' || usrDetails?.role == 'ITADMIN') && viewAttendeesFlag ? 'field col-12 md:col-3 sm:col-12' : "hidden"}>
                                            <label id="label" htmlFor="Password" className="mb-1 font-bold"  >Password</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText value={participantdata.password} onBlur={formik.handleBlur} name="password"
                                                onChange={(e) => { onInputChange(e, 'password'); formik.handleChange(e) }} disabled={disabled}
                                                placeholder="Enter Password" required maxLength="15"
                                                className={classNames({ 'p-invalid w-full': submitted && !participantdata.password })} />
                                            {submitted && formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                            {submitted && !participantdata.password && <small className="p-invalid">Password is required.</small>}
                                            {/* <Password value={password} onBlur={formik.handleBlur}
                                                type="password" name="password" placeholder="Enter Password" onChange={(e) => { setpassword(e.target.value); formik.handleChange(e) }} toggleMask feedback={false}
                                                className={classNames({ 'p-invalid': submitted && !password })} />
                                            {formik.touched.password && formik.errors.password && (<span className="p-invalid">{formik.errors.password}</span>)}
                                            {submitted && !password && <small className="p-invalid">Password is required.</small>} */}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                        </div>
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <hr className="hr-text" data-content="Travel Details"></hr>
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="traveltoeventcity">To Event City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown value={travelmodeindropdown} required onChange={(e) => { settravelmodeindropdown(e.target.value); onDropdownChange(e, 'travelmode_inbound') }} options={Travelmodes.Travelmode} optionLabel="name"
                                                className="w-full" disabled={disabled} placeholder={participantdata.travelmode_inbound == '' ? "Select Travel Mode (To Event City)" : participantdata.travelmode_inbound} />
                                            {submitted && !participantdata.travelmode_inbound && <small className="p-invalid">Travel mode is required</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="travelfromeventcity">From Event City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown value={travelmodeoutdropdown} required onChange={(e) => { settravelmodeoutdropdown(e.target.value); onDropdownChange(e, 'travelmode_outbound') }} options={Travelmodes.Travelmode} optionLabel="name"
                                                className="w-full" disabled={disabled} placeholder={participantdata.travelmode_outbound == '' ? "Select Travel Mode (From Event City)" : participantdata.travelmode_outbound} />
                                            {submitted && !participantdata.travelmode_outbound && <small className="p-invalid">Travel mode is required</small>}
                                        </div>
                                        {participantdata.inFlightBookingStatus ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="inFlightBookingStatus">In Flight Booking Status</label>
                                                <InputText value={participantdata.inFlightBookingStatus} required
                                                    className="w-full" disabled={true} />
                                            </div>
                                            : <></>
                                        }
                                        {participantdata.outFlightBookingStatus ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="inFlightBookingStatus">Out Flight Booking Status</label>
                                                <InputText value={participantdata.outFlightBookingStatus} required
                                                    className="w-full" disabled={true} />
                                            </div>
                                            : <></>}
                                        {/* To Event city */}
                                        {id && flightdata.length > 0 ?
                                            <div className="flex flex-column md:col-6 sm:col-12">
                                                <label id="label" className="mb-3" htmlFor="toeventcityflightdetails">To Event City Flight Details</label>
                                                {flightdata.map((item) => {
                                                    return (
                                                        <div className="col-12 p-0 card grid flightOrder">
                                                            <div class="col-fixed sm:col-12 md:col-4 lg:col-4 p-2 align-self-center align-items-center justify-content-center">{item.flightOrder}</div>
                                                            <div class="col grid p-0 m-0">
                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-2xl font-medium">{moment(item.departureDateTime).format('HH:mm')}</label>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <label className="text-xl">{moment(item.departureDateTime).format('DD/MM/YYYY')}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.departureName}>{item.departureAirportCode}</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-4xl font-medium">{item.timeDifference}</label>
                                                                    </div>
                                                                    <hr class="hr-flight-icon"></hr>
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-lg font-medium">{item.airlineName}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-xl">{item.flightNo}</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-2xl font-medium">{moment(item.arrivalDateTime).format('HH:mm')}</label>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <label className="text-xl">{moment(item.arrivalDateTime).format('DD/MM/YYYY')}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.arrivalName}>{item.arrivalAirportCode}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                            </div> : <></>}

                                        {/* From Event City */}
                                        {id && flightoutdata.length > 0 ?
                                            <div className="flex flex-column md:col-6">
                                                <label id="label" className="mb-3" htmlFor="fromeventcityflightdetails">From Event City Flight Details</label>
                                                {flightoutdata.map((item) => {
                                                    return (
                                                        <div className="col-12 p-0 card grid flightOrder">
                                                            <div class="col-fixed sm:col-12 md:col-4 lg:col-4 p-2 align-self-center align-items-center justify-content-center">{item.flightOrder}</div>
                                                            <div class="col grid p-0 m-0">
                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-2xl font-medium">{moment(item.departureDateTime).format('HH:mm')}</label>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <label className="text-xl">{moment(item.departureDateTime).format('DD/MM/YYYY')}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.departureName}>{item.departureAirportCode}</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-4xl font-medium">{item.timeDifference}</label>
                                                                    </div>
                                                                    <hr class="hr-flight-icon"></hr>
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-lg font-medium">{item.airlineName}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-3">
                                                                        <label className="text-xl">{item.flightNo}</label>
                                                                    </div>
                                                                </div>

                                                                <div class="col-12 sm:col-12 md:col-4 lg:col-4 p-2">
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-2xl font-medium">{moment(item.arrivalDateTime).format('HH:mm')}</label>
                                                                    </div>
                                                                    <div className="text-center">
                                                                        <label className="text-xl">{moment(item.arrivalDateTime).format('DD/MM/YYYY')}</label>
                                                                    </div>
                                                                    <div className="text-center line-height-4">
                                                                        <label className="text-4xl font-medium" data-toggle="tooltip" data-placement="left" title={item.arrivalName}>{item.arrivalAirportCode}</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )
                                                })}
                                                {/* </div> */}
                                            </div>
                                            : <></>}
                                        {id && ticketData.length > 0 ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="Downloadtickets">Download Tickets</label>
                                                {ticketData.map((item) => {
                                                    return (
                                                        <div className="flex align-items-center">
                                                            <label>{item.title}</label>
                                                            <i className="fa fa-download ml-2" style={{ cursor: 'pointer' }} onClick={(e) => DownloadFile(item.ticketPath)}></i>
                                                        </div>
                                                    )
                                                })}
                                            </div> : <></>}
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="BoardingCity">Boarding City</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter value={boardingcitydropdown} required onChange={(e) => { setboardingcitydropdown(e.target.value); onDropdownChange(e, 'boardingcity') }} options={boardingcity} optionLabel="name"
                                                className="w-full"
                                                id={classNames({ 'p-invalid': submitted && !participantdata.boardingcityname })} disabled={disabled} placeholder={participantdata.boardingcityname == '' ? "Select Boarding City" : participantdata.boardingcityname} />
                                            {submitted && !participantdata.boardingcityname && <small className="p-invalid">Boardingcity is required.</small>}
                                        </div>

                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <hr class="hr-text" data-content="Hospital Details"></hr>
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="hospitalname">Hospital/Clinic Name</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText value={participantdata.hospitalname?.toUpperCase()} required onChange={(e) => onInputChange(e, 'hospitalname')}
                                                // id={classNames({ 'p-invalid': submitted && !participantdata.hospitalname.trim() })} 
                                                disabled={disabled} placeholder="Enter Hospital/Clinic Name" maxLength="100" />
                                            {submitted && !participantdata.hospitalname.trim() && <small className="p-invalid">Hospital/clinic name is required.</small>}
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="clinicphone"> Phone No. </label>
                                            <InputText name="clinicphone" value={participantdata.clinicphone} required maxLength="20"
                                                onBlur={formik.handleBlur} onChange={(e) => { onInputChange(e, 'clinicphone'); formik.handleChange(e) }} disabled={disabled} placeholder="Enter Phone" />
                                            {formik.touched.clinicphone && formik.errors.clinicphone && (<span className="p-invalid">{formik.errors.clinicphone}</span>)}
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12" >
                                            <label id="label" htmlFor="Address">Address</label>
                                            <InputTextarea className="w-full" value={participantdata.address?.toUpperCase()} required onChange={(e) => onInputChange(e, 'address')} style={viewAttendeesFlag ? { overflow: 'hidden' } : { resize: "none" }} rows={5} cols={30} disabled={disabled} placeholder="Enter Address" maxLength="250" />
                                        </div>
                                        <div className="field col-12 md:col-6 sm:col-12">
                                            <label id="label" htmlFor="Remarks">Remarks</label>
                                            <InputTextarea className="w-full" value={participantdata.participantremarks?.toUpperCase()} required onChange={(e) => onInputChange(e, 'participantremarks')} style={viewAttendeesFlag ? { overflow: 'hidden' } : { resize: "none" }} rows={5} cols={30} disabled={disabled} placeholder="Enter Remarks" maxLength="500" />
                                        </div>
                                        <div className="field col-12 md:col-12 sm:col-12">
                                            <hr class="hr-text" data-content="Accomodation Booking Details"></hr>
                                        </div>
                                        <div className="field col-12 md:col-3 sm:col-12">
                                            <label id="label" htmlFor="accomodation">Need Accommodation</label>
                                            <div className="mt-2 w-3 flex gap-3">
                                                {needAccomodation.map((needaccomodation) => {
                                                    return (
                                                        <div key={needaccomodation.key} className="inline-flex align-items-center" >
                                                            <RadioButton inputId={needaccomodation.key} name="needaccomodation" value={needaccomodation.key} disabled={disabled} onChange={(e) => { setSelectedneedaccomodation(e.value); onDropdownChange(e, 'needaccomodation') }} checked={needaccomodation == [] ? selectedneedaccomodation.key === needaccomodation.key : needaccomodation.key === participantdata.needaccomodationcode} />
                                                            <label htmlFor={needaccomodation.key} className="ml-2 mr-2">{needaccomodation.name}</label>
                                                        </div>
                                                    );
                                                })}
                                                {/* <InputSwitch  checked={participantdata.needaccomodationcode} disabled={disabled} onChange={(e) => onDropdownChange(e, 'needaccomodation')} /> */}
                                            </div>
                                        </div>

                                        {usrDetails == null ? <></> : ((usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN')
                                            //  && id == ''
                                        ) ?
                                            <><div className="field col-12 md:col-12 sm:col-12">
                                                <hr class="hr-text" data-content="Company Details"></hr>
                                            </div><div className="field col-12 md:col-3 sm:col-12">
                                                    <label id="label" htmlFor="company">Company Name</label>
                                                    <Dropdown filter value={companydropdown} required onChange={(e) => { setCompanydropdown(e.target.value); onDropdownChange(e, 'Company'); }}
                                                        options={Companylist} optionLabel="name"
                                                        className="w-full" disabled={disabled} placeholder={participantdata.Companyname == '' ? "Select Company" : participantdata.Companyname} />
                                                </div></>
                                            : <></>}

                                        {id && participantdata.hotelName ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="hotelName">Hotel Name</label>
                                                <InputText value={participantdata.hotelName} required onChange={(e) => onInputChange(e, 'hotelName')}
                                                    disabled={true} />
                                            </div> : <></>}
                                        {(usrDetails == null ? <></> : usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN') && participantdata.registrationstatus.toLowerCase() == 'approved' ?
                                            <div className="field col-12 md:col-12 sm:col-12">
                                                <hr class="hr-text" data-content="Day Of Event"></hr>
                                            </div> : <></>}
                                        {(usrDetails == null ? <></> : usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN') && participantdata.registrationstatus.toLowerCase() == 'approved' ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="cardNo">Card No.</label>
                                                <InputText name="cardNo" onBlur={formik.handleBlur} maxLength="10" value={participantdata.cardNo}
                                                    required onChange={(e) => { onInputChange(e, 'cardNo'); formik.handleChange(e) }} disabled={disabled} />
                                                {formik.touched.cardNo && formik.errors.cardNo && (<span className="p-invalid">{formik.errors.cardNo}</span>)}
                                            </div> : <></>}

                                        {(usrDetails == null ? <></> : usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN') && participantdata.registrationstatus.toLowerCase() == 'approved' ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="cardNo">Trico Confirmed</label>
                                                <Dropdown name="TricoConfirmed" value={participantdata.TricoConfirmed}
                                                    placeholder={participantdata.TricoConfirmed != "" ? participantdata.TricoConfirmed : "Select Batch"} disabled={disabled}
                                                    required onChange={(e)=> onInputChange(e, 'TricoConfirmed')} options={batch} optionLabel="name" className="w-full" />
                                            </div> : <></>}

                                        {(usrDetails == null ? <></> : usrDetails.role == 'ADMIN' || usrDetails.role == 'ITADMIN') && participantdata.registrationstatus.toLowerCase() == 'approved' ?
                                            <div className="field col-12 md:col-3 sm:col-12">
                                                <label id="label" htmlFor="IsPresent">Is Present</label>
                                                <div className="mt-2 w-3 flex gap-3">
                                                    {/* <InputSwitch checked={participantdata.present} disabled={disabled} onChange={(e) => onInputChange(e, 'present')} /> */}
                                                    {present.map((present) => {
                                                        return (
                                                            <div key={present.key} className="inline-flex align-items-center" >
                                                                <RadioButton inputId={present.key} name="needaccomodation" value={present.key} disabled={disabled} onChange={(e) => { setselectedpresent(e.value); onDropdownChange(e, 'present') }} checked={present == [] ? selectedpresent === present.key : present.key === participantdata.present} />
                                                                <label htmlFor={present.key} className="ml-2 mr-2">{present.name}</label>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </div> : <></>}
                                    </div>

                                    <Toolbar className="field" left={leftToolbarTemplate}></Toolbar>

                                    <Dialog header={headertemplate} draggable={false} visible={registersuccessmessage} onHide={false} footer={footerContent} >
                                        <p>
                                            We have recieved your registration details,<br></br>
                                            TRICO secretariat will get in touch with you shortly.
                                        </p>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </AccessControl>
    )
};

export default AddParticipant;