import React, { useState, useEffect, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import axios from 'axios';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { MultiSelect } from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Paginator } from 'primereact/paginator';
import classNames from 'classnames';
import LoadingSpinner from "../Spinner/LoadingSpinner";
import exportData from "../../services/exportToExcelService";
import AccessControl from "../AccessControl";
import NoAccess from "../NoAccess";
import { RowsPerPageDropdown } from "../../enums/RowsPerPageOptions";
import * as Zones from "../../enums/Zones";

const State = () => {

    let emptyState = {
        id: '',
        name: '',
        countryId: '',
        countryName: '',
        zone: '',
    };

    // Hooks
    const [statelist, setStatelist] = useState([]);
    const [id, setid] = useState('');
    const [CreatedBy, SetCreatedBy] = useState('');
    const [Created, SetCreated] = useState('');
    const [dropdownlist, setdropdownlist] = useState([]);
    const [state, setState] = useState(emptyState);
    const [totalRecords, setTotalRecords] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [statefilter, setstatefilter] = useState('');
    const [countryfilter, setcountryfilter] = useState('');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [countrydropdowndata, setcountrydropdowndata] = useState(null);
    const [zonedropdown, setzonedropdown] = useState(null);

    // Flags
    const [AddnewDialog, setAddnewDialog] = useState(false);    // Adding New Country
    const [deleteDialog, setdeleteDialog] = useState(false);  // Deleting New Country
    const [submitted, setSubmitted] = useState(false);   //Checking Submitted Or Not
    const [enableFilter, setEnableFilter] = useState(false);
    const [isExportExcel, setIsExportExcel] = useState(false);

    // Const
    const UserName = localStorage.getItem('Username');
    // const date = new Date();
    const toast = useRef(null);
    const dt = useRef(null);
    const lazyState = useRef({
        first: 0,
        rows: RowsPerPageDropdown[0],
        page: 1,
        sortField: null,
        sortOrder: 1,
        state: null,
        country: null,
        zone:null,
    }); // For Sorting And Pagination Purpose

    // On Screen Load
    useEffect(() => {
        getstatelist();
        document.documentElement.style.setProperty('overflow', 'hidden');
    }, []);

    // - All Api

    // Get All State
    const getstatelist = (isExportExcel = false, event) => {
        setIsLoading(true);
        //Zone
        var listZoneFilterString = null;
        if (lazyState.current.zone != null && lazyState.current.zone != undefined && lazyState.current.zone != '') {
            if (lazyState.current.zone.length > 0) {
                if (lazyState.current.zone.length > 1) {
                    listZoneFilterString = lazyState.current.zone.map(item => item.name).join(',');
                } else {
                    listZoneFilterString = lazyState.current.zone.map(item => item.name)[0];
                }
            }
        }

        if (!isExportExcel) setStatelist([]);
        axios.post('/api/State/GetAll', {
            paginationRequired: !isExportExcel,
            SortDirection: lazyState.current.sortOrder,
            PageSize: lazyState.current.rows,
            CurrentPageNumber: event && event.rows ? ((event.first / event.rows) + 1) : lazyState.current.page,
            SortField: event ? event.sortField : "StateName",
            State: lazyState.current.state ? lazyState.current.state.trim() : null,
            Country: lazyState.current.country ? lazyState.current.country.trim() : null,
            Zone: listZoneFilterString ? listZoneFilterString : null,
        }).then(Response => {
            if (!isExportExcel) setStatelist([]);
            let tempData = [];
            for (let dataObj of Response.data.data) {
                // Pass body data in excelsheets.
                let allExcelData;
                let excelcolumnData = {
                    // StateId: dataObj.id,
                    Name: dataObj.stateName,
                    Country: dataObj.countryName,
                };
                if (selectedColumns.length > 0) {
                    for (let selectedColumn of selectedColumns) {
                        let fieldName = selectedColumn.field;
                        let headerName = selectedColumn.header;
                        excelcolumnData = { ...excelcolumnData, [headerName]: dataObj[fieldName] }
                    };
                    tempData.push(excelcolumnData);
                } else {
                    allExcelData = {
                        // StateId: dataObj.id,
                        Name: dataObj.stateName,
                        Country: dataObj.countryName
                    };
                    tempData.push(allExcelData);
                };
            };
            if (isExportExcel) {
                exportData(tempData, 'State');
                setIsLoading(false);
                return false;
            };
            setTotalRecords(Response.data.totalRecords);
            setStatelist(Response.data.data);
            getcountrylist();
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Get All Country In Dropdown
    const getcountrylist = () => {
        axios.post('/api/Country/GetAll', {
            paginationRequired: false
        }).then(Response => {
            let data = []
            for (const dataObj of Response.data.data) {
                data.push({ name: dataObj.name, code: dataObj.id });
            };
            setdropdownlist(data);
            setIsLoading(false);
        })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };

    // Get By Id for statelist
    const getstatebyid = (id) => {
        setIsLoading(true);
        axios.get('/api/State/GetAsync/' + id)
            .then((Response) => {
                setid(Response.data.id)
                SetCreatedBy(Response.data.createdBy);
                SetCreated(Response.data.created);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
            });
    };


    // Add New State
    const Insertupdatestate = () => {
        setSubmitted(true);
        if (state.name.trim() && state.countryName.trim()) {
            if (state.id == "") {
                const url = '/api/State/Insert';
                const data = {
                    "Name": state.name.trim(),
                    "CountryId": state.countryId,
                    "CreatedBy": UserName,
                    "Zone": state.zone.trim()
                    // "Created": date,
                };
                axios.post(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getstatelist(isExportExcel, lazyState.current);
                        setcountrydropdowndata(null)
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'New State Added', life: 3000 });
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: State With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'State Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
            if (state.id) {
                const url = '/api/State/Update';
                const data = {
                    "id": state.id,
                    "Name": state.name.trim(),
                    "CountryId": state.countryId,
                    "Zone": state.zone,
                    "CreatedBy": CreatedBy,
                    "Created": Created,
                    "ModifiedBy": UserName,
                    // "Modified": date
                };
                axios.put(url, data)
                    .then(() => {
                        setAddnewDialog(false);
                        getstatelist(isExportExcel, lazyState.current);
                        toast.current.show({ severity: 'success', summary: 'Successful', detail: 'State Updated', life: 3000 });
                        setcountrydropdowndata(null);
                        // document.documentElement.style.setProperty('overflow', 'auto');
                    }).catch((error) => {
                        if (error.response.data.includes("System.Exception: State With This Name Already Exist.")) {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'State Already Exist', life: 3000 });
                        }
                        else {
                            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Oops! Something Went Wrong!', life: 3000 });
                        };
                    });
            };
        };
    };

    // To Delete State
    const Deletestate = () => {
        setIsLoading(true);
        setState(emptyState);
        axios.delete('/api/State/Delete/' + id)
            .then((result) => {
                if (result.status === 200) {
                    setdeleteDialog(false);;
                    setIsLoading(false);
                    getstatelist(isExportExcel, lazyState.current);
                    toast.current.show({ severity: 'success', summary: 'Successful', detail: 'State Deleted', life: 3000 });
                    // document.documentElement.style.setProperty('overflow', 'auto');
                };
            })
            .catch((error) => {
                setIsLoading(false);
            });
    };

    // Functions

    // On Add New State
    const openNew = () => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setState(emptyState);
        setSubmitted(false);
        setAddnewDialog(true);

    };

    // On Edit State
    const editProduct = (countryObj) => {
        setAddnewDialog(true);
        getstatebyid(countryObj.id);
        let _state = { ...state };
        _state['id'] = (countryObj.id);
        _state['name'] = (countryObj.stateName);
        _state['countryId'] = (countryObj.countryId);
        _state['countryName'] = (countryObj.countryName);
        _state['zone'] = (countryObj.zone);
        setState(_state);
    };

    // On Delete State
    const confirmDeleteProduct = (country) => {
        // document.documentElement.style.setProperty('overflow', 'hidden')
        setdeleteDialog(true);
        setState(country);
        getstatebyid(country.id);
    };

    // On Hide Dialog For Edit / Add State
    const hideDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setAddnewDialog(false);
        setSubmitted(false);
    };

    // On Hide Delete Dialog
    const hideDeleteProductDialog = () => {
        // document.documentElement.style.setProperty('overflow', 'auto')
        setdeleteDialog(false);
    };

    // For Sorting 
    const onSort = (event) => {
        lazyState.current.sortField = event.sortField;
        lazyState.current.sortOrder = event.sortOrder;
        lazyState.current.first = event.first;
        lazyState.current.rows = event.rows;
        getstatelist(isExportExcel, lazyState.current);
    };

    //pagination
    const onBasicPageChange = (event) => {
        // const newPage = event.first / event.rows + 1;
        // if (newPage !== lazyState.current.first / lazyState.current.rows + 1) {
            setIsLoading(true);
            lazyState.current.sortField = lazyState.current.sortField;
            lazyState.current.sortOrder = lazyState.current.sortOrder;
            lazyState.current.first = event.first;
            lazyState.current.rows = event.rows;
            getstatelist(isExportExcel, lazyState.current);
            getcountrylist();
            setIsLoading(false);
        // };
    };

    //Template for pagination
    const template2 = {
        layout: ' CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
        'CurrentPageReport': (options) => {
            return (
                <span style={{ color: 'var(--text-color)', userSelect: 'none', width: '19%', textAlign: 'center' }}>
                    Showing {options.first} to {options.last} of {options.totalRecords} entries
                </span>
            );
        },
        RowsPerPageDropdown: (options) => {
            return <span>Items Per Page :<Dropdown value={options.value} options={RowsPerPageDropdown} onChange={options.onChange} /></span>;
        },
    };

    // All Buttons

    // Edit And Delete Buttons
    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <AccessControl
                    allowedPermissions={["edit:state"]}>
                    <Button icon="fa fa-pencil" className="p-button-rounded mr-2" onClick={() => editProduct(rowData)} tooltip="Edit" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
                <AccessControl
                    allowedPermissions={["delete:state"]}>
                    <Button icon="fa fa-trash" className="p-button-rounded mr-2" onClick={() => confirmDeleteProduct(rowData)} tooltip="Delete" tooltipOptions={{ position: 'bottom' }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Modals Button To Add State
    const productDialogFooter = (
        <>
            <Button label="Save" icon="fa fa-check" onClick={() => Insertupdatestate(id)} outlined />
            <Button label="Cancel" icon="fa fa-times" onClick={(e) => { hideDialog(e); setAddnewDialog(false); setcountrydropdowndata(null); }} outlined />
        </>
    );

    // Delete Confirmation Button
    const deleteProductDialogFooter = (
        <>
            <Button label="Yes" icon="fa fa-check" onClick={() => Deletestate(id)} outlined />
            <Button label="No" autoFocus icon="fa fa-times" onClick={() => hideDeleteProductDialog(false)} outlined />
        </>
    );

    // Left Side Toolbar
    const leftToolbarTemplate = () => {
        return (
            <AccessControl
                allowedPermissions={["add:state"]}>
                <div className="my-2">
                    <Button label="Add State" icon="fa fa-plus" onClick={openNew} outlined />
                </div>
            </AccessControl>
        )
    };

    // Input Change Event
    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || '';
        let _state = { ...state };
        _state[`${name}`] = val;
        setState(_state);
    };

    // Dropdown Change Event
    // const onDropdownChange = (e) => {
    //     let _state = { ...state };
    //     _state['countryId'] = (e.target && e.target.value.code) || '';
    //     _state['countryName'] = (e.target && e.target.value.name) || '';
    //     setState(_state);
    // };

    const onDropdownChange = (e, name) => {
        let _state = { ...state };
        if (name === 'countryid') {
            _state['countryId'] = (e.target && e.target.value.code) || '';
            _state['countryName'] = (e.target && e.target.value.name) || '';
            setState(_state);
        }
        else {
            const val = (e.target && e.target.value.name) || '';
            _state[`${name}`] = val;
            setState(_state);
        };
    };

    const StateTemplate = () => {
        return (
            <InputText name="statename" value={statefilter} placeholder="Enter State" onChange={(e) => setstatefilter(e.target.value)}
                onKeyDown={(e) => StateFilteronEnter(e)} style={{ width: '300px' }}></InputText>
        );
    };

    const CountryTemplate = () => {
        return (
            <InputText name="countryname" value={countryfilter} placeholder="Enter Country" onChange={(e) => setcountryfilter(e.target.value)}
                onKeyDown={(e) => CountryFilteronEnter(e)} style={{ width: '300px' }}></InputText>
        );
    };

    
    const ZoneFilterTemplate = () => {
        return (
            <MultiSelect
                value={lazyState.current.zone}
                options={Zones.Zone}
                itemTemplate={ZoneFilterItemTemplate}
                optionLabel="name"
                onChange={(e) => ZoneFilterClick(e)}
                placeholder="Select Any"
                className="p-column-filter"
                maxSelectedLabels={1}
                style={{ minWidth: '14rem' }}
                filter
            />
        );
    };

    const ZoneFilterItemTemplate = (option) => {
        return (
            <div className="flex align-items-center gap-2">
                <span>{option.name}</span>
            </div>
        );
    };

    const ZoneFilterBodyTemplate = (rowData) => {
        const zone = rowData.zone;
        return (
            <div className="flex align-items-center gap-2">
                <span>{zone}</span>
            </div>
        );
    };

    const ZoneFilterClick = (e) => {
        lazyState.current.zone = e.target.value ? e.target.value : null;
        getstatelist(isExportExcel, { Zone: e.value });
        e.preventDefault();
    }


    const CountryFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.country = e.target.value ? e.target.value : null
                getstatelist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.country = null
                getstatelist(isExportExcel, lazyState.current);
            };
        };
    };

    const StateFilteronEnter = (e) => {
        if (e.key == "Enter") {
            if (e.target.value.trim() != null && e.target.value.trim() != "") {
                setIsLoading(true);
                lazyState.current.state = e.target.value ? e.target.value : null
                getstatelist(isExportExcel, lazyState.current);
            } else {
                setIsLoading(true);
                lazyState.current.state = null
                getstatelist(isExportExcel, lazyState.current);
            };
        };
    };

    const removeFilter = () => {
        lazyState.current.state = null;
        setstatefilter(null);
        lazyState.current.country = null;
        setcountryfilter(null);
        getstatelist(isExportExcel, lazyState.current);
    };

    // Right Side Toolbar
    const rightToolbarTemplate = () => {
        return (
            <div className="my-2">
                <Button disabled={lazyState.current.country == null && lazyState.current.state == null ? true : false} icon="pi pi-filter-slash" className="mr-2" onClick={() => removeFilter()} tooltip="Reset Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined></Button>
                {/* {enableFilter ? <Button type="button" icon="pi pi-filter-slash" className=" mr-2 p-button-primary" onClick={(e) => setEnableFilter(false)} tooltip="Hide Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} /> : <Button type="button" icon="pi pi-filter" className="p-button-outlined mr-2"
                    onClick={(e) => setEnableFilter(true)} tooltip="Show Filter" tooltipOptions={{ position: "bottom", className: "showtooltip" }} />} */}
                <AccessControl
                    allowedPermissions={["export:state"]}>
                    <Button disabled={!(statelist != null && statelist.length > 0)} onClick={() => { getstatelist(true); }} icon="pi pi-file-excel" className="mr-2" tooltip="Export Excel" tooltipOptions={{ position: "bottom", className: "showtooltip" }} outlined />
                </AccessControl>
            </div>
        );
    };

    // Html Part
    return (
        <AccessControl
            allowedPermissions={["list:state"]}
            renderNoAccess={() => <NoAccess />}
        >
            <div className="trico-Grid">
                <Toast ref={toast} />
                {isLoading ? <LoadingSpinner /> :
                    (
                        <>
                            <div className="p-col">
                                <div className="card">
                                    <h5 className="headerfont"><span><i className="fa fa-solid fa-cog mr-2"></i>State</span></h5>
                                    <Toolbar left={leftToolbarTemplate} right={rightToolbarTemplate} className="mb-3"></Toolbar>
                                    <DataTable size="small" stripedRows ref={dt} value={statelist}
                                        lazy rows={lazyState.current.rows}
                                        onSort={onSort} sortField={lazyState.current.sortField} sortOrder={lazyState.current.sortOrder}
                                        removableSort scrollable scrollHeight="calc(100vh - 250px)"
                                        dataKey="id" className="datatable-responsive" responsiveLayout="scroll" filterDisplay={"row"}>
                                        <Column field="stateName" header="Name" sortField="StateName" sortable headerStyle={{ width: '40%', minWidth: '10rem' }} filter filterElement={StateTemplate} showFilterMenu={false}></Column>
                                        <Column field="countryName" header="Country" sortField="CountryName" sortable headerStyle={{ width: '30%', minWidth: '10rem' }} filter filterElement={CountryTemplate} showFilterMenu={false}></Column>
                                        <Column field="zone" header="Zone" sortField="Zone" sortable filter headerStyle={{ width: "10%", minWidth: "15rem" }} style={{ minWidth: '14rem' }} showFilterMenu={false} filterElement={ZoneFilterTemplate} body={ZoneFilterBodyTemplate} filterField="Zone" filterMenuStyle={{ width: '14rem' }}></Column>
                                        <Column header="Action" body={actionBodyTemplate}></Column>
                                    </DataTable>
                                    <Paginator className='justify-content-end' style={{ marginRight: "15px" }} first={lazyState.current.first} rows={lazyState.current.rows} totalRecords={totalRecords} template={template2} rowsPerPageOptions={RowsPerPageDropdown} onPageChange={onBasicPageChange} ></Paginator>

                                    <Dialog draggable={false} visible={AddnewDialog} style={{ width: '450px' }}
                                        //  header={state.id == '' ? <span><i className="fa fa-plus-square mr-2"></i>Add State</span> : <span><i className="fa fa-edit mr-2"></i>Update State</span>} 
                                        header="State Details"
                                        modal className="p-fluid" footer={productDialogFooter} onHide={hideDialog}>
                                        <div className="field">
                                            <label id="label" htmlFor="Zone">Zone</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter id="zone" value={zonedropdown} onChange={(e) => { setzonedropdown(e.target.value); onDropdownChange(e, 'zone') }}
                                                options={Zones.Zone} optionLabel="name" placeholder={state.zone == '' ? "Select Zone" : state.zone}
                                                className={classNames({ 'p-invalid': submitted && !state.zone })} />
                                            {submitted && !state.zone && <small className="p-invalid">Zone is required.</small>}
                                        </div>
                                        <div className="field">
                                            <label id="label" htmlFor="State">State</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <InputText maxlength="50" id="name" value={state.name} onChange={(e) => onInputChange(e, 'name')}
                                                placeholder="Enter State"
                                                required className={classNames({ 'p-invalid': submitted && !state.name })} />
                                            {submitted && !state.name && <small className="p-invalid">Name is required.</small>}
                                        </div>
                                        <div className="field">
                                            <label id="label" htmlFor="Country">Country</label>
                                            <label id="Mandatoryfield" >*</label>
                                            <Dropdown filter id="state" value={countrydropdowndata} onChange={(e) => { setcountrydropdowndata(e.target.value); onDropdownChange(e, 'countryid') }}
                                                options={dropdownlist} optionLabel="name" placeholder={state.countryId == '' ? "Select Country" : state.countryName}
                                                className={classNames({ 'p-invalid': submitted && !state.countryName })} />
                                            {submitted && !state.countryName && <small className="p-invalid">Country is required.</small>}
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteDialog} draggable={false} style={{ width: '450px' }} header="Confirm" modal footer={deleteProductDialogFooter} onHide={hideDeleteProductDialog}>
                                        <div className="flex align-items-center">
                                            <i className="fa fa-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            <span>Are you sure you want to delete <b>{state.stateName}</b> state?</span>
                                        </div>
                                    </Dialog>
                                </div>
                            </div>
                        </>
                    )
                };
            </div>
        </AccessControl>
    )
}

export default State;